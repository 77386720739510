import React, { FC, Fragment } from 'react';
import { YearRangeSlider } from './YearRangeSlider';
import { CardContent } from '@material-ui/core';
import { Today } from '@material-ui/icons';
import { FilterHeader } from './FilterHeader';

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
}

export const YearRange: FC<Props> = ({ expanded, toggleExpanded }) => (
  <Fragment>
    <FilterHeader
      icon={<Today />}
      onClick={toggleExpanded}
      text="Years"
    />
    {expanded && (
      <CardContent>
        <YearRangeSlider />
      </CardContent>
    )}
  </Fragment>
);
