import { DatabaseStats, NameRecordsByNameId, NameRecord } from 'common/entities';
import { createReducer } from 'typesafe-actions';
import { fetchDatabaseStatsAsync, fetchNameRecordsAsync } from './database-actions';
import { fetchPreviewNameAsyncAction } from '../preview/preview-actions';
import { indexBy, prop } from 'ramda';

const byId = indexBy<NameRecord>(prop('nameId'));

export interface DatabaseState extends DatabaseStats {
  database: NameRecordsByNameId;
  error?: Error;
  isLoading: boolean;
}

const initialState: DatabaseState = {
  database: {},
  endYear: null,
  isLoading: false,
  startYear: null,
  totalNameRecordsCount: null,
  yearTotals: [],
};

const success: Pick<DatabaseState, 'error' | 'isLoading'> = {
  error: undefined,
  isLoading: false,
};

export const databaseReducer = createReducer(initialState)
  .handleAction([
    fetchPreviewNameAsyncAction.request,
    fetchDatabaseStatsAsync.request,
    fetchNameRecordsAsync.request,
  ], state => ({
    ...state,
    error: undefined,
    isLoading: true,
  }))
  .handleAction([
    fetchPreviewNameAsyncAction.failure,
    fetchDatabaseStatsAsync.failure,
    fetchNameRecordsAsync.failure,
  ], (state, action) => ({
    ...state,
    error: action.payload,
    isLoading: false,
  }))
  .handleAction(fetchPreviewNameAsyncAction.success, (state, action) => ({
    ...state,
    ...success,
    database: {
      ...state.database,
      ...byId(action.payload.nameRecords),
    },
  }))
  .handleAction(fetchDatabaseStatsAsync.success, (state, {
    payload: {
      startYear,
      endYear,
      totalNameRecordsCount,
      yearTotals,
    },
  }) => ({
    ...state,
    ...success,
    endYear,
    startYear,
    totalNameRecordsCount,
    yearTotals,
  }))
  .handleAction(fetchNameRecordsAsync.success, (state, action) => ({
    ...state,
    ...success,
    database: {
      ...state.database,
      ...byId(action.payload.nameRecords),
    },
  }));
