import React, { FC } from 'react';
import { FilterHeader } from './FilterHeader';
import { Equalizer } from '@material-ui/icons';
import { CardContent } from '@material-ui/core';
import { DatabaseStatisticsChart } from './DatabaseStatisticsChart';

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
}

export const DatabaseStatistics: FC<Props> = ({ expanded, toggleExpanded }) => (
  <div>
    <FilterHeader
      icon={<Equalizer />}
      onClick={toggleExpanded}
      text="Database Statistics"
    />

    {expanded && (
      <CardContent>
        <DatabaseStatisticsChart />
      </CardContent>
    )}
  </div>
);
