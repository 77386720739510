import { createAction } from 'typesafe-actions';
import { Name, YearRange } from 'common/entities';

export const changeLastNameAction = createAction('config/CHANGE_LAST_NAME')<Name>();

export const changeYearRangeAction = createAction('config/CHANGE_YEAR_RANGE')<YearRange>();

export const setExpertModeAction = createAction('config/SET_EXPERT_MODE')<boolean>();

export const setShowDatabaseStatistics = createAction('config/SET_SHOW_DATABASE_STATISTICS')<boolean>();
