import React, { FC } from 'react';

import Joyride, { TooltipRenderProps } from 'react-joyride';
import { selectTourSteps, selectTourRun } from '../store/tour/tour-selectors';
import { useSelector } from 'react-redux';
import { secondaryColor } from '../tools/theme';
import { Button, Paper as MuiPaper, DialogTitle, DialogContent, DialogContentText, DialogActions, withStyles } from '@material-ui/core';

const Paper = withStyles({
  root: {
    margin: '0 2em',
    maxWidth: 400,
    zIndex: 1000,
  },
})(MuiPaper);

const Tooltip: FC<TooltipRenderProps> = props => {
  const {
    index,
    continuous,
    backProps,
    closeProps,
    primaryProps,
    step: {
      content,
      title,
    },
    tooltipProps,
  } = props;
  return (
    <Paper {...tooltipProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {index > 0 && (
          <Button {...backProps}>
            Back
          </Button>
        )}
        {continuous && (
          <Button color="primary" variant="contained" {...primaryProps}>
            Next
          </Button>
        )}
        {!continuous && (
          <Button {...closeProps}>
            Close
          </Button>
        )}
      </DialogActions>
    </Paper>
  );
};

export const Tour: FC = () => {
  const steps = useSelector(selectTourSteps);
  const run = useSelector(selectTourRun);

  return (
    <Joyride
      continuous
      run={run}
      showProgress
      showSkipButton
      steps={steps}
      styles={{
        beacon: {
          outline: 'none',
        },
        buttonNext: {
          fontFamily: 'Roboto',
        },
        options: {
          primaryColor: secondaryColor,
          zIndex: 10_000,
        },
      }}
      tooltipComponent={Tooltip}
    />
  );
};
