import React, { FC } from 'react';
import { selectDatabaseYearTotals } from '../store/database/database-selectors';
import { ResponsiveContainer, LineChart, Label, YAxis, CartesianGrid, Line, Legend, Tooltip, XAxis } from 'recharts';
import { useSelector } from 'react-redux';
import { uniq, flatten, pipe, map, keys, without, omit } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import { getColors, itemSorter } from '../tools/utils';
import { databaseStatisticsLabels } from 'common/data';
import { YearTotals } from 'common/entities';
import { Typography, Grid } from '@material-ui/core';

export const DatabaseStatisticsChart: FC = () => {
  const yearTotals = useSelector(selectDatabaseYearTotals);

  const data = (omits: (keyof YearTotals)[]) => pipe(
    map(omit(omits)),
  )(yearTotals);

  const lines = (omits: (keyof YearTotals)[]) => pipe(
    map(keys),
    // @ts-expect-error some kind of ramda thing
    keys => uniq(flatten(keys)),
    without(['year', ...omits] as (keyof YearTotals)[]),
    mapIndexed((line: keyof YearTotals, index, list) => (
      <Line
        dataKey={line}
        dot={false}
        key={line}
        stroke={getColors(list.length)[index]}
        strokeWidth={2}
        type="monotone"
      />
    )),
    // @ts-expect-error some kind of ramda thing
  )(yearTotals);

  const totalsOmits: (keyof YearTotals)[] = ['maleNames', 'femaleNames', 'totalNames'];
  const totalsData = data(totalsOmits);
  const totalsLines = lines(totalsOmits);

  const namesOmits: (keyof YearTotals)[] = ['males', 'females', 'total'];
  const namesData = data(namesOmits);
  const namesLines = lines(namesOmits);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">All Births</Typography>
        <ResponsiveContainer
          aspect={16 / 9}
          maxHeight={250}
          minHeight={100}
          minWidth={100}
          width="100%"
        >
          <LineChart data={totalsData}>
            <YAxis
              domain={[0, 4400000]}
              tickFormatter={(tick: number) => (tick === 0 ? '0' : `${(tick / 1_000_000)?.toLocaleString()}M`)}
              ticks={[0, 1100000, 2200000, 3300000, 4400000]}
              type="number"
            >
              <Label angle={-90} offset={10} position="insideBottomLeft">Babies Born</Label>
            </YAxis>
            <XAxis dataKey="year" />

            <CartesianGrid strokeDasharray="3 3" />
            <Legend
              formatter={(value: keyof YearTotals) => databaseStatisticsLabels[value]}
            />
            <Tooltip
              formatter={(value, name) => (
                [value.toLocaleString(), databaseStatisticsLabels[name as keyof YearTotals]]
              )}
              itemSorter={itemSorter({ greatestFirst: true })}
            />
            {totalsLines}
          </LineChart>
        </ResponsiveContainer>
      </Grid>

      <Grid item xs={12}>
        <Typography align="center" variant="h6">All Names</Typography>
        <ResponsiveContainer
          aspect={16 / 9}
          maxHeight={250}
          minHeight={100}
          minWidth={100}
          width="100%"
        >
          <LineChart data={namesData}>
            <YAxis
              domain={[0, 36000]}
              tickFormatter={(tick: number) => (tick === 0 ? '0' : `${(tick / 1_000)?.toLocaleString()}k`)}

              type="number"
            >
              <Label angle={-90} offset={10} position="insideBottomLeft">Names Given</Label>
            </YAxis>

            <XAxis dataKey="year" />

            <CartesianGrid strokeDasharray="3 3" />
            <Legend
              formatter={(value: keyof YearTotals) => databaseStatisticsLabels[value]}
            />
            <Tooltip
              formatter={(value, name) => (
                [value.toLocaleString(), databaseStatisticsLabels[name as keyof YearTotals]]
              )}
              itemSorter={itemSorter({ greatestFirst: true })}
            />
            {namesLines}
          </LineChart>
        </ResponsiveContainer>
      </Grid>
    </Grid >
  );
};
