import { ApplicationState } from '.';
import { selectLastName, selectYearRange, selectYearDefaults, selectExpertMode } from './config/config-selectors';
import { selectSelectedNameIds } from './selected/selected-selectors';

export const selectQueryParams = (store: ApplicationState) => {
  // Note Well: the order things are added to `queryContainer` will be the order they appear in the query string
  const queryContainer = [];

  const expertMode = selectExpertMode(store);
  if (expertMode) {
    queryContainer.push('expertMode=true');
  }

  const [startYear, endYear] = selectYearRange(store);
  const [defaultStartYear, defaultEndYear] = selectYearDefaults(store);
  if (startYear !== defaultStartYear) {
    queryContainer.push(`from=${startYear}`);
  }
  if (endYear !== defaultEndYear) {
    queryContainer.push(`to=${endYear}`);
  }

  const lastName = selectLastName(store);
  if (lastName.length > 0) {
    queryContainer.push(`lastName=${lastName}`);
  }

  const selectedNames = selectSelectedNameIds(store);
  if (selectedNames.length > 0) {
    queryContainer.push(`names=${selectedNames.join(',')}`);
  }

  const query = queryContainer.join('&');
  return query.length > 0 ? `?${query}` : '';
};
