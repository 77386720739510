import React, { FC } from 'react';
import { withStyles, Box, Typography, Button, IconButton as MuiIconButton, Select, MenuItem, CardContent } from '@material-ui/core';
import { selectSorts } from '../store/search/search-selectors';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowUpward as MuiArrowUpward, ArrowDownward as MuiArrowDownward, Sort as SortIcon, Add, Close } from '@material-ui/icons';
import { modifySortAction, addSortAction, removeSortAction } from '../store/search/search-actions';
import { mapIndexed } from 'ramda-adjunct';
import { Sort, StatisticType, Direction, Calculation } from 'common/entities';
import {
  calculationLabelsByCalculation,
  calculationOrderSortedByLabels,
  createSort,
  directionLabelsByDirection,
  directionOrder,
  statisticTypeLabelsByName,
  statisticsOrder,
} from 'common/data';
import { map } from 'ramda';
import { FilterHeader } from './FilterHeader';

const Wrapper = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: '50%',
    flexDirection: 'column',
  },
})(Box);

const Counter = withStyles({
  root: {
    alignSelf: 'flex-end',
    fontSize: 16,
    marginBottom: 5,
    marginRight: 10,
  },
})(Typography);

const IconButton = withStyles({
  root: {
    alignSelf: 'center',
  },
})(MuiIconButton);

const ArrowUpward = withStyles({
  root: {
    fontSize: 18,
    marginLeft: 5,
    marginRight: 5,
  },
})(MuiArrowUpward);

const ArrowDownward = withStyles({
  root: {
    fontSize: 18,
    marginLeft: 5,
    marginRight: 5,
  },
})(MuiArrowDownward);

const Row = withStyles({
  root: {
    alignItems: 'flex-end',
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: 0,
  },
})(Box);



interface SortFieldProps {
  index: number;
  modifySort: (sort: Sort) => void;
  removeSort: () => void;
  sort: Sort;
}

const SortField: FC<SortFieldProps> = ({ index, modifySort, removeSort, sort }) => (
  <Row key={index}>
    <Counter>{index + 1}.</Counter>

    <Select
      onChange={event => {
        modifySort({
          ...sort,
          calculation: event.target.value as Calculation,
        });
      }}
      value={sort.calculation}
    >
      {map(calculation => (
        <MenuItem key={calculation} value={calculation}>
          {calculationLabelsByCalculation[calculation]}
        </MenuItem>
      ), calculationOrderSortedByLabels)}
    </Select>

    <Select
      onChange={event => {
        modifySort({
          ...sort,
          type: event.target.value as StatisticType,
        });
      }}
      value={sort.type}
    >
      {map(type => (
        <MenuItem key={type} value={type}>
          {statisticTypeLabelsByName[type]}
        </MenuItem>
      ), statisticsOrder)}
    </Select>

    <Select
      onChange={event => {
        modifySort({
          ...sort,
          direction: event.target.value as Direction,
        });
      }}
      value={sort.direction}
    >
      {map(direction => (
        <MenuItem key={direction} value={direction}>
          {direction === 'ascending' ? <ArrowUpward /> : <ArrowDownward />}
          {directionLabelsByDirection[direction]}
        </MenuItem>
      ), directionOrder)}
    </Select>


    <IconButton onClick={removeSort} size="small">
      <Close fontSize="small" />
    </IconButton>
  </Row>
);

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
}

export const Sorts: FC<Props> = ({ expanded, toggleExpanded }) => {
  const dispatch = useDispatch();
  const sorts = useSelector(selectSorts);

  const addSort = () => {
    const sort = createSort();
    dispatch(addSortAction(sort));
  };

  const removeSort = (index: number) => () => {
    dispatch(removeSortAction(index));
  };

  const modifySort = (index: number) => (sort: Sort) => {
    dispatch(modifySortAction({
      index,
      sort,
    }));
  };

  return (
    <Wrapper>
      <FilterHeader
        action={
          <Button
            className="tutorial__NamePool-addSort"
            onClick={addSort}
            startIcon={<Add />}
          >
            Add Sort
          </Button>
        }
        icon={<SortIcon />}
        onClick={toggleExpanded}
        text="Sort"
      />

      {expanded && (
        <CardContent>
          {mapIndexed((sort, index) => (
            <SortField
              index={index}
              key={index}
              modifySort={modifySort(index)}
              removeSort={removeSort(index)}
              sort={sort}
            />
          ), sorts)}
        </CardContent>
      )}
    </Wrapper>
  );
};
