import { createReducer } from 'typesafe-actions';
import { aboutModalVisibleAction, appMenuVisibleAction } from './ui-actions';

export interface UIState {
  aboutModalVisible: boolean;
  appMenuVisible: boolean;
}

const initialState: UIState = {
  aboutModalVisible: false,
  appMenuVisible: false,
};

export const uiReducer = createReducer(initialState)
  .handleAction(aboutModalVisibleAction, (state, action) => ({
    ...state,
    aboutModalVisible: action.payload,
  }))
  .handleAction(appMenuVisibleAction, (state, action) => ({
    ...state,
    appMenuVisible: action.payload,
  }));
