import { ApplicationState } from '..';
import { map } from 'ramda';
import { compact } from 'ramda-adjunct';
import { NameRecord } from 'common/entities';
import { selectNameRecordByNameId } from '../database/database-selectors';

export const selectMatchCount = (store: ApplicationState) => store.searchState.matchCount;
export const selectYearRangeCount = (store: ApplicationState) => store.searchState.yearRangeCount;
export const selectLimit = (store: ApplicationState) => store.searchState.limit;
export const selectFilters = (store: ApplicationState) => store.searchState.filters;
export const selectSorts = (store: ApplicationState) => store.searchState.sorts;
export const selectFiltersIsLoading = (store: ApplicationState) => store.searchState.isLoading;
export const selectFiltersError = (store: ApplicationState) => store.searchState.error;

export const selectNamePoolNameIds = (store: ApplicationState) => store.searchState.nameIds;

// https://github.com/char0n/ramda-adjunct/pull/1294 requires the typing of the output
export const selectNamePool = (store: ApplicationState): NameRecord[] => (selectNamePoolNameIds(store) === null ? [] : (
  compact(map(
    selectNameRecordByNameId(store),
    selectNamePoolNameIds(store),
  ))
));
