import { configReducer } from './config/config-reducer';
import { databaseReducer } from './database/database-reducer';
import { previewReducer } from './preview/preview-reducer';
import { searchReducer } from './search/search-reducer';
import { selectedReducer } from './selected/selected-reducer';
import { tourReducer } from './tour/tour-reducer';
import { uiReducer } from './ui/ui-reducer';

import { databaseStatsSaga, databaseNameSaga } from './database/database-sagas';
import { previewSaga } from './preview/preview-sagas';
import { requestSearchSaga, searchSaga, searchDatabaseSaga } from './search/search-sagas';

import { combineReducers } from 'redux';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';
import { StateType } from 'typesafe-actions';
import { fetchDatabaseStatsAsync, fetchNameRecordsAsync } from './database/database-actions';
import { fetchPreviewNameAsyncAction } from './preview/preview-actions';
import { fetchSearchAsyncAction, modifyFilterAction, addFilterAction, removeFilterAction, modifySortAction } from './search/search-actions';
import { changeYearRangeAction } from './config/config-actions';
import { _copyHrefAction } from './notification/notification-actions';
import { notificationSaga } from './notification/notification-sagas';

export const createRootReducer = combineReducers({
  configState: configReducer,
  databaseState: databaseReducer,
  previewState: previewReducer,
  searchState: searchReducer,
  selectedState: selectedReducer,
  tourState: tourReducer,
  uiState: uiReducer,
});

export type ApplicationState = StateType<typeof createRootReducer>;

export function* rootSaga() {
  yield all([
    takeEvery(fetchDatabaseStatsAsync.request, databaseStatsSaga),
    takeEvery(fetchPreviewNameAsyncAction.request, previewSaga),
    takeEvery(fetchNameRecordsAsync.request, databaseNameSaga),
    takeEvery([
      addFilterAction,
      changeYearRangeAction,
      modifyFilterAction,
      modifySortAction,
      removeFilterAction,
    ], requestSearchSaga),
    takeLatest(fetchSearchAsyncAction.request, searchSaga),
    takeLatest(fetchSearchAsyncAction.success, searchDatabaseSaga),
    takeEvery(_copyHrefAction, notificationSaga),
  ]);
}
