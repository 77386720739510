import React, { FC, Fragment } from 'react';
import { Drawer, IconButton as MuiIconButton, List as MuiList, ListItem, ListItemIcon, ListItemText, withStyles, Theme, Dialog, DialogTitle, DialogContent as MuiDialogContent, SvgIcon as MuiSvgIcon, useTheme, useMediaQuery } from '@material-ui/core';
import { MoreVert, Info, School } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppMenuVisible, selectAboutModalVisible } from '../store/ui/ui-selectors';
import { appMenuVisibleAction, aboutModalVisibleAction } from '../store/ui/ui-actions';
import { Chicken } from '../assets/icons';
import { CloseButton } from './CloseButton';
import { startTourAction } from '../store/tour/tour-actions';

const List = withStyles({
  root: {
    width: 250,
  },
})(MuiList);

const DialogContent = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
})(MuiDialogContent);

const SvgIcon = withStyles({
  root: {
    height: 200,
    margin: 50,
    width: 200,
  },
})(MuiSvgIcon);

const IconButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
}))(MuiIconButton);

const AboutModal: FC = () => {
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const visible = useSelector(selectAboutModalVisible);
  if (!visible) {
    return null;
  }
  const onClose = () => {
    dispatch(aboutModalVisibleAction(false));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      open
    >
      <DialogTitle>
        About This App
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <SvgIcon><Chicken /></SvgIcon>
      </DialogContent>
    </Dialog>
  );
};

export const AppMenu: FC = () => {
  const dispatch = useDispatch();
  const appMenuVisible = useSelector(selectAppMenuVisible);

  const openMenu = () => {
    dispatch(appMenuVisibleAction(true));
  };

  const closeMenu = () => {
    dispatch(appMenuVisibleAction(false));
  };
  const showAboutModal = () => {
    dispatch(aboutModalVisibleAction(true));
    dispatch(appMenuVisibleAction(false));
  };
  const startTour = () => {
    dispatch(startTourAction());
    dispatch(appMenuVisibleAction(false));
  };

  return (
    <Fragment>
      <IconButton onClick={openMenu}><MoreVert /></IconButton>

      <Drawer
        anchor="right"
        keepMounted
        onClose={closeMenu}
        open={appMenuVisible}
      >
        <List>
          <ListItem button onClick={showAboutModal}>
            <ListItemIcon><Info /></ListItemIcon>
            <ListItemText>About</ListItemText>
          </ListItem>

          <ListItem button onClick={startTour}>
            <ListItemIcon><School /></ListItemIcon>
            <ListItemText>Take Tour</ListItemText>
          </ListItem>
        </List>
      </Drawer>

      <AboutModal />
    </Fragment>
  );
};
