import { NameId } from 'common/entities';
import { createReducer } from 'typesafe-actions';
import {
  selectNameAction,
  unselectNameAction,
  clearAllSelectedNamesAction,
  selectNamesAction,
} from './selected-actions';
import { reject, equals } from 'ramda';

export interface SelectedState {
  selectedNameIds: NameId[];
}

const initialState: SelectedState = {
  selectedNameIds: [],
};

export const selectedReducer = createReducer(initialState)
  .handleAction(selectNameAction, (state, action) => ({
    ...state,
    selectedNameIds: [...state.selectedNameIds, action.payload],
  }))
  .handleAction(selectNamesAction, (state, action) => ({
    ...state,
    selectedNameIds: [...state.selectedNameIds, ...action.payload],
  }))
  .handleAction(unselectNameAction, (state, action) => ({
    ...state,
    selectedNameIds: reject(equals(action.payload), state.selectedNameIds),
  }))
  .handleAction(clearAllSelectedNamesAction, state => ({
    ...state,
    selectedNameIds: [],
  }));
