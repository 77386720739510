import { reduce, mergeDeepRight } from 'ramda';
import { YearRange, Year, YearData, NameId, Name, Sex } from './entities';
import { inRange } from 'ramda-adjunct';

// @ts-expect-error some kind of ramda thing
export const mergeDeepAll = <T>(array: T[]) => reduce<T, T>(mergeDeepRight, {} as T, array);

export const yearIsInRange = ([startYear, endYear]: YearRange) => (year: Year) => (
  inRange(startYear, endYear + 1, year)
);

export const frequencyIsInRange = (yearRange: YearRange) => ({ year }: YearData) => (
  yearIsInRange(yearRange)(year)
);

export const getNameFromNameId = (nameId: NameId): Name => (
  nameId.replace(/-[FM]$/, '')
);

export const getSexFromNameId = (nameId: NameId) => (
  nameId.substr(-1)
) as Sex;
