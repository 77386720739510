import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Chip as MuiChip,
  Tab,
  Tabs as MuiTabs,
  Typography,
  Box,
  CircularProgress as MuiCircularProgress,
  useTheme,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { Done, RemoveCircleOutline, AddCircleOutline } from '@material-ui/icons';
import { NamesChart } from './NamesChart';
import { NameRecord, SexOption } from 'common/entities';
import { BOTH, MALE, FEMALE, commonSexName, sexNames } from 'common/data';
import { filterSex } from '../tools/utils';
import { SummaryStatistics } from './SummaryStatistics';
import { isEmpty, map, whereEq, find, findIndex, equals } from 'ramda';
import { selectYearRange, selectLastName } from '../store/config/config-selectors';
import { useSelector, useDispatch } from 'react-redux';
import { selectBothForName } from '../store/database/database-selectors';
import { selectNameAction, unselectNameAction } from '../store/selected/selected-actions';
import {
  selectPreviewName,
  selectPreviewModalVisible,
  selectPreviewSex,
  selectPreviewTab,
  selectPreviewIsLoading,
  selectPreviewNameIds,
  selectPreviewOpenedFrom,
} from '../store/preview/preview-selectors';
import { selectNameIsSelected } from '../store/selected/selected-selectors';
import { changePreviewTabAction, closePreviewModalAction, fetchPreviewNameAsyncAction } from '../store/preview/preview-actions';
import { withStyles } from '@material-ui/styles';
import { Rooster } from '../assets/icons';
import { Swipeable, SwipeCallback } from 'react-swipeable';
import { CloseButton } from './CloseButton';

const Chip = withStyles({
  root: {
    marginLeft: 12,
  },
})(MuiChip);

const Tabs = withStyles({
  root: {
    marginBottom: 12,
  },
})(MuiTabs);

const NoResultsWrapper = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 0',
  },
})(Box);

const NoResultsTitle = withStyles({
  root: {
    margin: 16,
  },
})(Typography);

const NoResultsDescription = withStyles({
  root: {
    margin: 16,
    maxWidth: 400,
  },
})(Typography);

const CircularProgress = withStyles({
  root: {
    minHeight: 200,
    minWidth: 200,
  },
})(MuiCircularProgress);

const CircularProgressWrapper = withStyles({
  root: {
    padding: 100,
  },
})(Box);

interface TabPanelProps {
  nameRecords: NameRecord[];
  previewName: string;
  sex: SexOption;
  visible: boolean;
}
const TabPanel: FC<TabPanelProps> = ({ nameRecords, previewName, sex, visible }) => {
  const [startYear, endYear] = useSelector(selectYearRange);

  if (isEmpty(nameRecords)) {
    return (
      <div hidden={!visible} role="tabpanel">
        <NoResultsWrapper>
          <Rooster />
          <NoResultsTitle variant="h5">You found a rare one!</NoResultsTitle>
          <NoResultsDescription>
            No records were found for baby <strong>{commonSexName[sex].plural}</strong>{' '}
            born with the name <strong>{previewName}</strong> from {startYear} to {endYear}.
          </NoResultsDescription>
        </NoResultsWrapper>
      </div>
    );
  }

  return (
    <div hidden={!visible} role="tabpanel">
      <NamesChart nameRecords={nameRecords} />
      {map(
        nameRecord => (
          <SummaryStatistics key={nameRecord.nameId} nameRecord={nameRecord} />
        ),
        nameRecords,
      )}
    </div>
  );
};

const DialogTitle = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(3)}px ${theme.spacing(2)}px 0 ${theme.spacing(3)}px`,
    },
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px 0 ${theme.spacing(5)}px`,
  },
}))(MuiDialogTitle);


export const PreviewNameDialog: FC = () => {
  const dispatch = useDispatch();

  const previewName = useSelector(selectPreviewName);
  const isLoading = useSelector(selectPreviewIsLoading);
  const previewTab = useSelector(selectPreviewTab);
  const previewSex = useSelector(selectPreviewSex) ?? previewTab;
  const modalVisible = useSelector(selectPreviewModalVisible);
  const lastName = useSelector(selectLastName);
  const nameRecords = useSelector(selectBothForName(previewName));
  const nameIdsGroup = useSelector(selectPreviewNameIds);
  const openedFrom = useSelector(selectPreviewOpenedFrom);

  const fullScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const selectedSex = previewTab === BOTH ? previewSex : previewTab;
  const nameRecord = find<NameRecord>(whereEq({ name: previewName, sex: selectedSex }), nameRecords);

  const isSelected = useSelector(selectNameIsSelected(nameRecord?.nameId));

  if (!modalVisible || previewName === null) {
    return null;
  }

  const selectName = () => {
    if (nameRecord?.nameId) {
      dispatch(selectNameAction(nameRecord.nameId));
    }
  };
  const unselectName = () => {
    if (nameRecord?.nameId) {
      dispatch(unselectNameAction(nameRecord.nameId));
    }
  };
  const closePreviewModal = () => {
    dispatch(closePreviewModalAction());
  };

  if (isLoading) {
    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth="md"
        onClose={closePreviewModal}
        open
      >
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      </Dialog>
    );
  }

  const femaleNameRecord = filterSex(FEMALE)(nameRecords);
  const maleNameRecord = filterSex(MALE)(nameRecords);
  const hasBoth = !isEmpty(femaleNameRecord) && !isEmpty(maleNameRecord);

  const onSwiped: SwipeCallback = eventData => {
    const { dir } = eventData;
    if (!nameRecord || !openedFrom) {
      return;
    }
    if (!(dir === 'Left' || dir === 'Right')) {
      // we want to ignore any other directions of swiping
      return;
    }

    const { nameId } = nameRecord;
    const currentIndex = findIndex(equals(nameId), nameIdsGroup);
    let nextIndex: null | number = null;

    if (dir === 'Right' && currentIndex > 0) {
      nextIndex = currentIndex - 1;
    }

    if (dir === 'Left' && currentIndex < (nameIdsGroup.length - 1)) {
      nextIndex = currentIndex + 1;
    }

    if (nextIndex === null) {
      dispatch(closePreviewModalAction());
    } else {
      const nextNameId = nameIdsGroup[nextIndex];
      dispatch(fetchPreviewNameAsyncAction.request({ nameId: nextNameId, openedFrom }));
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={closePreviewModal}
      open
    >
      <Swipeable onSwiped={onSwiped}>
        <DialogTitle
          onDoubleClick={() => {
            console.log(nameRecord);
          }}
        >
          {previewName}
          {lastName ? ` ${lastName}` : ''}
          {isSelected && previewTab !== BOTH && (
            <Chip icon={<Done />} label={`${sexNames[previewTab].singular} selected`} size="small" />
          )}

          <CloseButton onClose={closePreviewModal} />
        </DialogTitle>

        <DialogContent>
          <Tabs
            onChange={(event, newValue) => {
              dispatch(changePreviewTabAction(newValue));
            }}
            value={previewTab}
          >
            <Tab label="Female" value={FEMALE} />
            {hasBoth && (
              <Tab label="Both" value={BOTH} />
            )}
            <Tab label="Male" value={MALE} />
          </Tabs>

          <TabPanel
            nameRecords={femaleNameRecord}
            previewName={previewName}
            sex={FEMALE}
            visible={previewTab === FEMALE}
          />
          {hasBoth && (
            <TabPanel
              nameRecords={nameRecords}
              previewName={previewName}
              sex={BOTH}
              visible={previewTab === BOTH}
            />
          )}
          <TabPanel
            nameRecords={maleNameRecord}
            previewName={previewName}
            sex={MALE}
            visible={previewTab === MALE}
          />
        </DialogContent>

        {nameRecord && (
          <DialogActions>
            {isSelected && (
              <Button
                disabled={!isSelected}
                onClick={unselectName}
                startIcon={<RemoveCircleOutline />}
                variant="contained"
              >
                Remove from Selected Names
              </Button>
            )}
            {!isSelected && (
              <Button
                color="primary"
                disabled={isSelected}
                onClick={selectName}
                startIcon={<AddCircleOutline />}
                variant="contained"
              >
                Add {previewName} ({sexNames[selectedSex].singular}) to Selected Names
              </Button>
            )}
          </DialogActions>
        )}
      </Swipeable>
    </Dialog>
  );
};
