import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';

import 'regenerator-runtime/runtime';

import { composeWithDevTools } from 'redux-devtools-extension';

import { createRootReducer, rootSaga } from './index';
import { analyticsMiddleware } from './analyticsMiddleware';
import { analytics } from 'firebase';

interface StoreArgs {
  analytics: analytics.Analytics;
}

export const configureStore = ({ analytics }: StoreArgs) => {
  const composeEnhancers = composeWithDevTools({});
  const sagaMiddleware = createSagaMiddleware();


  const middlewares = applyMiddleware(
    sagaMiddleware,
    analyticsMiddleware(analytics),
  );


  const store = createStore(
    createRootReducer,
    {},
    composeEnhancers(middlewares),
  );

  sagaMiddleware.run(rootSaga);
  return store;
};
