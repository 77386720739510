import React, { FC } from 'react';
import { NameRecord, YearData } from 'common/entities';
import { commonSexName } from 'common/data';
import { isEmpty, filter, pipe, whereEq, map, join } from 'ramda';
import { Typography, Box, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { capitalize, toOrdinal } from '../tools/utils';
import { useSelector } from 'react-redux';
import { selectYearRange } from '../store/config/config-selectors';
import { calculateSummaryStatistics } from 'common/statistics';

const yearOrYears = (any: any[]) => (isEmpty(any) ? 'years' : 'year');
const wasOrWere = (any: any[]) => (isEmpty(any) ? 'were' : 'was');

const niceList = (strings: string[] | number[]) => pipe(
  map(String),
  join(', '),
  str => {
    if (strings.length === 1) {
      return str;
    }
    if (strings.length === 2) {
      return `${String(strings[0])} and ${String(strings[1])}`;
    }

    const lastItemIndex = str.lastIndexOf(', ');
    if (lastItemIndex === -1) {
      return str;
    }
    const firstItems = str.slice(0, lastItemIndex);
    const lastItem = str.slice(lastItemIndex + 1);
    return `${firstItems}, and${lastItem}`;
  },
)(strings);

const Wrapper = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em',
  },
}))(Box);

const Subtitle = withStyles({
  root: {
    marginBottom: 16,
    marginTop: -12,
  },
})(Typography);

interface Props {
  nameRecord: NameRecord;
}

export const SummaryStatistics: FC<Props> = ({ nameRecord }) => {
  const yearRange = useSelector(selectYearRange);
  const [startYear, endYear] = yearRange;

  const { data, name, sex } = nameRecord;
  const summaryStats = calculateSummaryStatistics(yearRange)(nameRecord);

  if (summaryStats === null) {
    console.error("couldn't find stats for", { nameRecord });
    return null;
  }

  const { rank, count } = summaryStats;

  const extract = (test: Partial<YearData>) => pipe(
    (data: YearData[]) => filter(whereEq(test), data),
    map(({ year }) => year),
  )(data);

  const mostBornYears = extract({ count: count.max });
  const leastBornYears = extract({ count: count.min });

  // In case this seems counterintuitive: recall that a "high" ranking is a rank like `1` and a "low" ranking is a rank like `10000`.  High rankings are low numbers (mathematically speaking).
  const highestRankedYears = extract({ rank: rank.min });
  const lowestRankedYears = extract({ rank: rank.max });

  return (
    <Wrapper>
      <Typography component="span" variant="h3">
        {capitalize(commonSexName[sex].plural)} named {name}
      </Typography>
      <Subtitle variant="subtitle2">
        Fun facts about baby {commonSexName[sex].plural} named {name}{' '}
        born between {startYear} and {endYear}.
      </Subtitle>

      <div style={{ maxWidth: 600 }}>
        <Typography variant="h5">Ranking</Typography>
        <ul>
          <Typography component="li">
            {name} was the{' '}
            {`${Math.floor(rank.mean).toLocaleString()}${toOrdinal(Math.floor(rank.mean))}`} ranked{' '}
            name for {commonSexName[sex].plural} between {startYear} and {endYear} on average. Its{' '}
            median ranking was {Math.floor(rank.median).toLocaleString()}.
          </Typography>

          <Typography component="li">
            {name} was the highest ranked during {niceList(highestRankedYears)}{' '}
            at a rank of {rank.min.toLocaleString()}.
          </Typography>

          <Typography component="li">
            The lowest ranked {yearOrYears(lowestRankedYears)} {wasOrWere(lowestRankedYears)}{' '}
            {niceList(lowestRankedYears)} at a rank of {rank.max.toLocaleString()}.
          </Typography>
        </ul>

        <Typography variant="h5">Total Born</Typography>
        <ul>
          <Typography component="li">
            The {yearOrYears(mostBornYears)} with the most babies born with the name {name}{' '}
            {wasOrWere(mostBornYears)} {niceList(mostBornYears)} with a total of {count.max.toLocaleString()}.
          </Typography>

          <Typography component="li">
            The {yearOrYears(leastBornYears)} with the least {wasOrWere(leastBornYears)}{' '}
            {niceList(leastBornYears)} with a total of {count.min.toLocaleString()}.
          </Typography>

          <Typography component="li">
            {count.sum.toLocaleString()} total babies were born between {startYear} and {endYear}.
          </Typography>
        </ul>
      </div>
    </Wrapper>
  );
};
