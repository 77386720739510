import { call, put, select } from 'redux-saga/effects';
import { fetchPreviewNameAsyncAction } from './preview-actions';
import { length } from 'ramda';
import { callApi, DATABASE_API } from '../../tools/utils';
import { selectBothForName } from '../database/database-selectors';
import { NameRecord } from 'common/entities';
import { getNameFromNameId } from 'common/utils';

export function* previewSaga(action: ReturnType<typeof fetchPreviewNameAsyncAction.request>) {
  const name = getNameFromNameId(action.payload.nameId);
  const cachedNameRecords: NameRecord[] = yield select(selectBothForName(name));

  try {
    if (length(cachedNameRecords) === 2) {
      yield put(fetchPreviewNameAsyncAction.success({ nameRecords: cachedNameRecords }));
    } else {
      const nameRecords: ReturnType<typeof fetchPreviewNameAsyncAction.success>['payload'] = yield call(
        callApi,
        'GET',
        `${DATABASE_API}/name/${name}`,
      );
      yield put(fetchPreviewNameAsyncAction.success(nameRecords));
    }
  } catch (error) {
    yield put(fetchPreviewNameAsyncAction.failure(error));
  }
}
