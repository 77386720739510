import React, { useState, useEffect } from 'react';
import { Slider as MuiSlider, Box, useTheme, useMediaQuery } from '@material-ui/core';
import { YearRange } from 'common/entities';

import { pipe, range, reject, map, includes, uniq, anyPass, allPass } from 'ramda';
import { inRange } from 'ramda-adjunct';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { changeYearRangeAction } from '../store/config/config-actions';
import { selectYearRange } from '../store/config/config-selectors';
import { selectDatabaseYearBounds } from '../store/database/database-selectors';

const Slider = withStyles({
  markLabelActive: {
    top: -22,
  },
  root: {
    marginBottom: 20,
    marginLeft: 18,
    marginRight: 18,
    marginTop: 20,
  },
})(MuiSlider);

const SliderWrapper = withStyles({
  root: {
    display: 'flex',
    marginBottom: '-8px',
  },
})(Box);

export const YearRangeSlider = () => {
  const dispatch = useDispatch();

  const bounds = useSelector(selectDatabaseYearBounds);

  const reduxYearRange = useSelector(selectYearRange);
  const setReduxYearRange = (yearRange: YearRange) => {
    dispatch(changeYearRangeAction(yearRange));
  };

  const [yearRange, setYearRange] = useState<YearRange>(reduxYearRange);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    if (isChanging) {
      return;
    }
    if (reduxYearRange[0] !== yearRange[0] || reduxYearRange[1] !== yearRange[1]) {
      setYearRange(reduxYearRange);
    }
  }, [reduxYearRange, yearRange, isChanging]);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isXSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const isXLarge = useMediaQuery(theme.breakpoints.only('xl'));

  if (bounds[0] === null || bounds[1] === null) {
    return null;
  }

  // eslint-disable-next-line no-nested-ternary
  const interval = isXSmall ? 25 : (isSmall ? 20 : (isXLarge ? 10 : 15));
  const overlapInterval = Math.floor(interval / 2) + 2;

  const decades = pipe(
    (bounds: YearRange) => range(bounds[0], bounds[1] + 1),
    reject((value: number) => value % interval !== 0),
    reject(value => includes(value, [...bounds, ...yearRange])),
  )(bounds as YearRange);

  const baseMarks: number[] = [...bounds as YearRange, ...yearRange, ...decades];

  const isNearControl = (value: number) => {
    const predicates = [
      inRange(yearRange[0], yearRange[0] + overlapInterval),
      inRange(yearRange[1] - overlapInterval, yearRange[1]),
    ];
    return anyPass(predicates)(value);
  };

  const checkOverlap = (value: number) => allPass([
    value => value === yearRange[0] && value !== yearRange[1],
    inRange(yearRange[1] - overlapInterval, yearRange[1] + overlapInterval),
  ])(value);

  const marks = pipe(
    uniq as (number: number[]) => number[],
    map(value => ({
      label:
        (isNearControl(value) && !includes(value, yearRange)) || checkOverlap(value) ? '' : value,
      value,
    })),
  )(baseMarks);

  return (
    <SliderWrapper>
      <Slider
        marks={marks}
        max={bounds[1]}
        min={bounds[0]}
        onChange={(event, yearRange) => {
          setYearRange(yearRange as YearRange);
          setIsChanging(true);
        }}
        onChangeCommitted={(event, yearRange) => {
          setReduxYearRange(yearRange as YearRange);
          setIsChanging(false);
        }}
        value={yearRange}
      />
    </SliderWrapper>
  );
};
