import { ApplicationState } from '..';
import { any, equals, map } from 'ramda';
import { compact } from 'ramda-adjunct';
import { NameId, NameRecord } from 'common/entities';
import { selectNameRecordByNameId } from '../database/database-selectors';

// https://github.com/char0n/ramda-adjunct/pull/1294 requires the typing of the output
export const selectSelectedNames = (store: ApplicationState): NameRecord[] => compact(map(
  selectNameRecordByNameId(store),
  store.selectedState.selectedNameIds,
));

export const selectSelectedNameIds = (store: ApplicationState) => store.selectedState.selectedNameIds;

export const selectNameIsSelected = (nameId: NameId | null | undefined) => (store: ApplicationState) => {
  if (nameId) {
    return any(equals(nameId), store.selectedState.selectedNameIds);
  }
  return false;
};
