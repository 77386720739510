import React, { FC } from 'react';
import {
  LineChart,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  ReferenceLine,
} from 'recharts';
import { mapIndexed } from 'ramda-adjunct';

import { NameRecord } from 'common/entities';
import { getColors, getChartData, itemSorter, getNameLabel } from '../tools/utils';
import { useSelector } from 'react-redux';
import { selectYearRange } from '../store/config/config-selectors';
import { primaryColor } from '../tools/theme';

interface Props {
  nameRecords: NameRecord[];
}

export const NamesChart: FC<Props> = ({ nameRecords }) => {
  const colors = getColors(nameRecords.length);
  const yearRange = useSelector(selectYearRange);
  const chartData = getChartData(nameRecords, yearRange);

  const lines = mapIndexed(({ name, sex, nameId }, index) => (
    <Line
      dataKey={getNameLabel({ name, sex })}
      dot={{ fill: colors[index], r: 2, stroke: colors[index], strokeWidth: 0 }}
      key={nameId}
      stroke={colors[index]}
      strokeWidth={2}
      type="monotone"
    />
  ),
  nameRecords);

  return (
    <ResponsiveContainer aspect={16 / 9} minHeight={200} minWidth={200} width="97%">
      <LineChart data={chartData} style={{ overflow: 'visible' }} >
        <YAxis domain={[1, 'dataMax']} interval="preserveStartEnd" reversed type="number">
          <Label angle={-90} position="insideLeft" value="Rank" />
        </YAxis>

        <XAxis dataKey="year" />

        <ReferenceLine stroke={primaryColor} strokeDasharray="1 1" y={1000}>
          <Label fill={primaryColor} position="right">1000</Label>
        </ReferenceLine>

        <CartesianGrid strokeDasharray="3 3" />
        <Legend />
        <Tooltip
          formatter={(value, name) => [value.toLocaleString(), name]}
          itemSorter={itemSorter()}
        />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  );
};
