import * as math from 'mathjs';
import { NameRecord, SingleStatistics, Statistics, YearRange, StatisticType, Calculation } from './entities';
import { frequencyIsInRange } from './utils';

/*
 * ramda has been avoided in this file out of an abundance of caution.
 * The code here is a VERY VERY hot path on the server side.
 */

type StatisticsFunc = {
  [calculation in Calculation]: (data: number[]) => number
};

const calcMedianSorted = (sortedData: number[]) => {
  const hasEvenNumberOfDatapoints = !(sortedData.length & 1); // eslint-disable-line no-bitwise
  const middleIndex = Math.floor(sortedData.length / 2);
  if (hasEvenNumberOfDatapoints) {
    return (sortedData[middleIndex - 1] + sortedData[middleIndex]) / 2;
  }
  return sortedData[middleIndex];
};

/* eslint-disable @typescript-eslint/no-unsafe-return */
/** Use this when you only want to get one calculation in isolation */
export const statisticFuncs: StatisticsFunc = {
  mad: data => math.mad(data),

  max: data => Math.max(...data),

  // note to javascript ninjas: reduce is now benchmarking as the most performant way to do this
  mean: data => (data.length > 0 ? data.reduce((a, b) => a + b, 0) / data.length : 0),

  median: data => math.median(data),

  min: data => Math.min(...data),

  std: data => math.std(data),

  // note to javascript ninjas: reduce is now benchmarking as the most performant way to do this
  sum: data => data.reduce((a, b) => a + b, 0),

  variance: data => math.variance(data),
};
/* eslint-enable @typescript-eslint/no-unsafe-return */

const toFour = (value: number) => Number(value.toFixed(4));

/** Use this function when you want to calculate all statistics */
const calculateAllStatistics = (data: number[]): SingleStatistics => {
  const sorted = data.sort((a, b) => a - b);
  const sum = statisticFuncs.sum(sorted);
  const mean = sorted.length <= 0 ? 0 : sum / sorted.length;
  const variance = statisticFuncs.mean(sorted.map(datum => (datum - mean) ** 2));
  const median = calcMedianSorted(sorted);
  const mad = calcMedianSorted(
    sorted
      .map(datum => Math.abs(datum - median))
      .sort((a, b) => a - b),
  );
  const min = sorted[0];
  const max = sorted[sorted.length - 1];
  const std = Math.sqrt(variance);

  return {
    mad: toFour(mad),
    max,
    mean: toFour(mean),
    median,
    min,
    std: toFour(std),
    sum,
    variance: toFour(variance),
  };
};

export const extractDataInYearRange = (yearRange: YearRange, nameRecord: NameRecord) => {
  if (!nameRecord.data) {
    console.error({ nameRecord });
  }
  return nameRecord.data.filter(frequencyIsInRange(yearRange));
};

export const calculateSummaryStatistics = (yearRange: YearRange) => (
  nameRecord: NameRecord,
): Statistics | null => {
  const data = extractDataInYearRange(yearRange, nameRecord);
  if (data.length === 0) {
    console.log('no data in range', { nameRecord, yearRange });
    return null;
  }

  const generateStatistic = (type: StatisticType) => (
    calculateAllStatistics(data.map(datum => datum[type]))
  );

  // here is where goroutines would be very handy to have :)
  return {
    count: generateStatistic('count'),
    rank: generateStatistic('rank'),
  };
};

export const calculateOneStatistic = (
  yearRange: YearRange,
  type: StatisticType,
  calculation: Calculation,
) => (nameRecord: NameRecord) => {
  const data = extractDataInYearRange(yearRange, nameRecord);
  if (data.length === 0) {
    return null;
  }
  return statisticFuncs[calculation](data.map(datum => datum[type]));
};
