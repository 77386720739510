import { createAsyncAction } from 'typesafe-actions';
import { DatabaseStats, NameRecord, NameId } from 'common/entities';

export const fetchDatabaseStatsAsync = createAsyncAction(
  'database/FETCH_DATABASE_STATS_REQUEST',
  'database/FETCH_DATABASE_STATS_SUCCESS',
  'database/FETCH_DATABASE_STATS_FAILURE',
)<undefined, DatabaseStats, Error>();

export const fetchNameRecordsAsync = createAsyncAction(
  'database/FETCH_NAME_RECORDS_REQUEST',
  'database/FETCH_NAME_RECORDS_SUCCESS',
  'database/FETCH_NAME_RECORDS_FAILURE',
)<{ nameIds: NameId[] }, { nameRecords: NameRecord[] }, Error>();
