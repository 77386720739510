import React, { FC } from 'react';
import { Box, withStyles, Typography } from '@material-ui/core';
import { transparentize } from 'polished';
import { primaryColor } from '../tools/theme';

const TitleWrapper = withStyles({
  root: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-start',
  },
})(Box);

const IconWrapper = withStyles({
  root: {
    display: 'flex',
    marginRight: 10,
  },
})(Box);

const HeaderSectionWrapper = withStyles({
  root: {
    background: transparentize(0.9, primaryColor),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 10,
    width: '100%',
  },
})(Box);

interface Props {
  action?: JSX.Element;
  icon: JSX.Element;
  onClick: () => void;
  text: string;
}

export const FilterHeader: FC<Props> = ({ action, icon, onClick, text }) => (
  <HeaderSectionWrapper>
    <TitleWrapper onClick={onClick}>
      <IconWrapper>{icon}</IconWrapper>
      <Typography variant="h6">{text}</Typography>
    </TitleWrapper>
    {action}
  </HeaderSectionWrapper>
);
