import React, { FC, useRef } from 'react';
import {
  Button,
  TextField as MuiTextField,
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  Box,
  IconButton as MuiIconButton,
  CardContent,
} from '@material-ui/core';
import { map, reject, equals } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import { withStyles } from '@material-ui/styles';
import { Add, Close, FilterList } from '@material-ui/icons';
import {
  BOTH,
  SEX_LABELS,
  createFilter,
  filterOperatorOrder,
  filterSubjectOrder,
  filterTypeLabels,
  filterTypeOrder,
  sexOptionsOrder,
  subjectLabels,
} from 'common/data';
import { ToggleButtonGroup as MuiToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { SexOption, Filter, FilterSubject, FilterType, Operator } from 'common/entities';
import { useDispatch, useSelector } from 'react-redux';
import { selectFilters } from '../store/search/search-selectors';
import {
  addFilterAction,
  removeFilterAction,
  modifyFilterAction,
} from '../store/search/search-actions';
import { FilterHeader } from './FilterHeader';

const FilterFieldWrapper = withStyles({
  root: {
    alignItems: 'flex-end',
    display: 'flex',
    height: 33,
    justifyContent: 'center',
    marginBottom: 0,
    width: 300,
  },
})(Box);

const MenuItem = withStyles({
  root: {},
})(MuiMenuItem);

const TextField = withStyles({
  root: {
    minWidth: 100,
  },
})(MuiTextField);

const Select = withStyles({
  root: {
    width: 85,
  },
  select: {
    paddingLeft: 3,
    width: 'inherit',
  },
})(MuiSelect);

const ToggleButtonGroup = withStyles({
  root: {
    marginBottom: '0.5em',
  },
})(MuiToggleButtonGroup);

const IconButton = withStyles({
  root: {
    alignSelf: 'center',
  },
})(MuiIconButton);

const Wrapper = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
})(Box);

const FiltersWrapper = withStyles({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
})(Box);

interface FilterFieldProps {
  filter: Filter;
  modifyFilter: (filter: Partial<Filter>) => void;
  removeFilter: () => void;
}

export const FilterField: FC<FilterFieldProps> = ({
  filter,
  modifyFilter,
  removeFilter,
}) => {
  const { operator, subject, type, value, inputType } = filter;
  const textFieldRef = useRef<HTMLInputElement>(null);

  if (subject === 'sex') {
    return (
      <ToggleButtonGroup
        exclusive
        onChange={(event, value) => {
          if (value === null) {
            return;
          }
          modifyFilter({ value });
        }}
        size="small"
        value={value ?? BOTH}
      >
        {sexOptionsOrder.map(sexOption => (
          <ToggleButton key={sexOption} value={sexOption}>
            {SEX_LABELS[sexOption as SexOption]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }

  return (
    <div className="tutorial__FilterField">
      <FilterFieldWrapper>
        <Select
          onChange={event => {
            const subject = event.target.value as FilterSubject;
            modifyFilter({ subject });
          }}
          value={subject}
        >
          {map((subject: FilterSubject) => (
            <MenuItem key={subject} value={subject}>
              {subjectLabels[subject]}
            </MenuItem>
          ), reject(equals('sex'), filterSubjectOrder))}
        </Select>

        <Select
          onChange={event => {
            const type = event.target.value as FilterType;
            modifyFilter({ type });
          }}
          value={type}
        >
          {map((type: FilterType) => (
            <MenuItem key={type} value={type}>
              {filterTypeLabels[type]}
            </MenuItem>
          ), filterTypeOrder(subject).sort((a, b) => (
            filterTypeLabels[a].localeCompare(filterTypeLabels[b])
          )))}
        </Select>

        {operator !== null && (
          <Select
            onChange={event => {
              const operator = event.target.value as Operator;
              modifyFilter({ operator });
            }}
            value={operator}
          >
            {map(operator => (
              <MenuItem key={operator} value={operator}>
                {operator}
              </MenuItem>
            ), filterOperatorOrder)}
          </Select>
        )}

        <TextField
          inputRef={textFieldRef}
          onChange={event => {
            modifyFilter({
              value: event.target.value,
            });
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              textFieldRef.current?.blur();
            }
          }}
          type={inputType}
          value={value ?? ''}
        />

        <IconButton onClick={removeFilter} size="small">
          <Close fontSize="small" />
        </IconButton>
      </FilterFieldWrapper>
    </div >
  );
};

interface Props {
  expanded: boolean;
  toggleExpanded: () => void;
}

export const Filters: FC<Props> = ({ expanded, toggleExpanded }) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);

  const addFilter = () => {
    const filter = createFilter();
    dispatch(addFilterAction(filter));
  };

  function modifyFilter(index: number, filter: Filter) {
    return (partial: Partial<Filter>) => {
      const newFilter = createFilter({
        ...filter,
        ...partial,
      });
      dispatch(modifyFilterAction({
        filter: newFilter,
        index,
      }));
    };
  }

  const removeFilter = (index: number) => () => {
    dispatch(removeFilterAction(index));
  };

  return (
    <Wrapper>
      <FilterHeader
        action={
          <Button
            className="tutorial__NamePool-addFilter"
            onClick={addFilter}
            startIcon={<Add />}
          >
            Add Filter
          </Button>
        }
        icon={<FilterList />}
        onClick={toggleExpanded}
        text="Filter"
      />

      {expanded && (
        <CardContent>
          <FiltersWrapper>
            {mapIndexed((filter, index) => (
              <FilterField
                filter={filter}
                key={index}
                modifyFilter={modifyFilter(index, filter)}
                removeFilter={removeFilter(index)}
              />
            ), filters)}
          </FiltersWrapper>
        </CardContent>
      )}
    </Wrapper>
  );
};
