import { NameRecord, Sex, Name, YearData, YearRange, Rank, NameLabel } from 'common/entities';
import {
  filter,
  map,
  pipe,
  range,
  replace,
  toUpper,
  values,
  whereEq,
  reject,
  pluck,
  concat,
  sortBy,
  prop,
} from 'ramda';
import { contained } from 'ramda-adjunct';
import { adjustHue } from 'polished';
import { primaryColor } from './theme';
import { mergeDeepAll, frequencyIsInRange } from 'common/utils';
import { ItemSorter, TooltipPayload } from 'recharts';

export const capitalize = replace(/^./, toUpper);

const ordinalInput = ['st', 'nd', 'rd'];
export const toOrdinal = (input: number) => ordinalInput[((((input + 90) % 100) - 10) % 10) - 1] ?? 'th';


/**
 *
 * @example [1, 0.9,    0.8,    0.7,    0.6,    0.5,    0.4,    0.3,    0.1, 0] =>
 *          [1, 0.4305, 0.1678, 0.0576, 0.0168, 0.0039, 0.0007, 0.0001, 0,   0]
 */
// export const popularity = (value: number) => ((value * 100) ** 8) / (100 ** 8);

const cutoff = 1000;
export const popularity = (value: Rank) => (
  value > cutoff ? 0 : (cutoff - value) / cutoff
);

export const filterName = (name: Name) => filter(whereEq({ name }));
export const filterSex = (sex: Sex) => filter(whereEq({ sex }));

export const getColors = (count: number) => pipe(
  range(0),
  map(index => (index / count) * 360),
  map(rotate => adjustHue(rotate, primaryColor)),
)(count);

///////////////////////////////////////
// Database Stuff                     /
///////////////////////////////////////

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars-experimental */

const localNode = 'http://localhost:8007/api/v1';
const dockerNode = 'http://localhost:8008/api/v1';
const localRust = 'http://localhost:8077/api/v1';
const dockerRust = 'http://localhost:8088/api/v1';
const envVar = process.env.REACT_APP_DATABASE_API;

export const DATABASE_API = process.env.NODE_ENV === 'production' ? envVar : localNode;

/* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-unused-vars-experimental */


export async function callApi(method: string, query: RequestInfo, data?: BodyInit | null) {
  const response = await fetch(query, {
    body: JSON.stringify(data),
    headers: new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method,
  });
  return response.json();
}

///////////////////////////////////////
// Chart Stuff                        /
///////////////////////////////////////

interface ItemSorterOptions {
  greatestFirst?: boolean;
}

export const itemSorter = ({ greatestFirst }: ItemSorterOptions = {}): ItemSorter<TooltipPayload> => item => (
  (item.value as number) * (greatestFirst ? -1 : 1)
);

type DataValue<T extends keyof YearData> = {
  [nameLabel in NameLabel]: YearData[T];
};

export type ChartData<T extends keyof YearData> = DataValue<T> & YearDatapoint;

type YearDatapoint = Pick<YearData, 'year'>;

export const getNameLabel = ({ name, sex }: Pick<NameRecord, 'name' | 'sex'>): NameLabel => `${name} [${sex}]`;

const getDataPoint = (stat: keyof YearData, yearRange: YearRange) => (nameRecords: NameRecord[]) => (
  map(
    (nameRecord: NameRecord) => pipe(
      (nameRecord: NameRecord) => nameRecord.data,
      filter(frequencyIsInRange(yearRange)),
      map(frequency => ({
        [frequency.year]: {
          [getNameLabel(nameRecord)]: frequency[stat],
          year: frequency.year,
        },
      })),
      mergeDeepAll,
    )(nameRecord),
    nameRecords,
  )
);

const addAllYears = (yearRange: YearRange) => (chartData: ChartData<'rank'>[]) => pipe(
  ([startYear, endYear]: YearRange) => range(startYear, endYear + 1),
  range => reject(contained(pluck('year', chartData)), range),
  map(year => ({ year })),
  concat(chartData),
  sortBy(prop('year')),
)(yearRange);

export const getChartData = (nameRecords: NameRecord[], yearRange: YearRange): ChartData<'rank'>[] => pipe(
  getDataPoint('rank', yearRange),
  mergeDeepAll,
  values,
  addAllYears(yearRange),
)(nameRecords);

export const downloadFile = (input: string, filename: string) => {
  const element = document.createElement('a');
  const file = new Blob([input], { type: 'text/plain' });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const stripUrlProtocol = (url: string) => url.replace(/^(https?:|)\/\//, '');
