import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { Routes } from './components/Routes';
import theme from './tools/theme';
import { configureStore } from './store/configureStore';
import { Provider } from 'react-redux';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { BreakpointIndicator } from 'mui-breakpoint-indicator';
import { setupFirebase } from './firebase';

window.R = R;
window.RA = RA;

const { analytics } = setupFirebase();

const store = configureStore({ analytics });
const debugMode = false;

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />

      <BreakpointIndicator visible={debugMode} />
      <Routes />
    </ThemeProvider>
  </Provider>,
  document.querySelector('#root'),
);
