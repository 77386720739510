import { call, put, select } from 'redux-saga/effects';
import { fetchDatabaseStatsAsync, fetchNameRecordsAsync } from './database-actions';
import { DatabaseStats, NameRecord, NameId } from 'common/entities';
import { selectNameRecordByNameId } from './database-selectors';
import { callApi, DATABASE_API } from '../../tools/utils';
import { map } from 'ramda';
import { compact } from 'ramda-adjunct';

export function* databaseStatsSaga() {
  try {
    const stats: DatabaseStats = yield call(callApi, 'GET', `${DATABASE_API}/stats`);
    yield put(fetchDatabaseStatsAsync.success(stats));
  } catch (error) {
    yield put(fetchDatabaseStatsAsync.failure(error));
  }
}

export function* databaseNameSaga(action: ReturnType<typeof fetchNameRecordsAsync.request>) {
  const { nameIds } = action.payload;
  const nameRecordSelector: (nameId: NameId) => NameRecord = yield select(selectNameRecordByNameId);
  // https://github.com/char0n/ramda-adjunct/pull/1294 requires the typing of the output
  const cachedNameRecords: NameRecord[] = compact(map(nameRecordSelector, nameIds));

  try {
    if (cachedNameRecords.length === nameIds.length) {
      yield put(fetchNameRecordsAsync.success({ nameRecords: cachedNameRecords }));
    } else {
      const { nameRecords }: { nameRecords: NameRecord[] } = yield call(
        callApi,
        'POST',
        `${DATABASE_API}/byNameIds`,
        { nameIds } as unknown as BodyInit,
      );
      yield put(fetchNameRecordsAsync.success({ nameRecords }));
    }
  } catch (error) {
    yield put(fetchNameRecordsAsync.failure(error));
  }
}
