import { ApplicationState } from '..';
import { Name, NameId, NameRecord } from 'common/entities';
import { compact } from 'ramda-adjunct';

export const selectNameRecordByNameId = (store: ApplicationState) => (nameId: NameId) => (
  store.databaseState.database[nameId]
);

export const selectDatabaseNameIds = (store: ApplicationState) => (
  Object.keys(store.databaseState.database)
);

export const selectDatabaseIsLoading = (store: ApplicationState) => (
  store.databaseState.isLoading
);

export const selectDatabaseYearBounds = (store: ApplicationState) => ([
  store.databaseState.startYear,
  store.databaseState.endYear,
]);

export const selectDatabaseError = (store: ApplicationState) => (
  store.databaseState.error
);

export const selectDatabaseYearTotals = (store: ApplicationState) => (
  store.databaseState.yearTotals
);

export const selectTotalNameRecordsCount = (store: ApplicationState) => (
  store.databaseState.totalNameRecordsCount
);

// https://github.com/char0n/ramda-adjunct/pull/1294 requires the typing of the output
export const selectBothForName = (name: Name | null) => (store: ApplicationState): NameRecord[] => (
  name === null ? [] : compact([
    selectNameRecordByNameId(store)(`${name}-F`),
    selectNameRecordByNameId(store)(`${name}-M`),
  ])
);
