import { Name, YearRange } from 'common/entities';
import { createReducer } from 'typesafe-actions';
import {
  changeLastNameAction,
  changeYearRangeAction,
  setExpertModeAction,
  setShowDatabaseStatistics,
} from './config-actions';

export interface ConfigState {
  expertMode: boolean;
  lastName: Name;
  showDatabaseStatistics: boolean;
  yearDefaults: YearRange;
  yearRange: YearRange;
}

const initialState: ConfigState = {
  expertMode: false,
  lastName: '',
  showDatabaseStatistics: false,
  yearDefaults: [1970, 2023],
  yearRange: [1970, 2023],
};

export const configReducer = createReducer(initialState)
  .handleAction(changeLastNameAction, (state, action) => ({
    ...state,
    lastName: action.payload,
  }))
  .handleAction(changeYearRangeAction, (state, action) => ({
    ...state,
    yearRange: action.payload,
  }))
  .handleAction(setExpertModeAction, (state, action) => ({
    ...state,
    expertMode: action.payload,
  }))
  .handleAction(setShowDatabaseStatistics, (state, action) => ({
    ...state,
    showDatabaseStatistics: action.payload,
  }));
