import {
  SexOption,
  Filter,
  FilterType,
  Direction,
  StatisticType,
  Sort,
  Operator,
  FilterSubject,
  YearTotals,
  Calculation,
} from './entities';
import { pipe, filter, find, whereEq, map, pluck, chain, uniq, sort } from 'ramda';

////////////////////////////////////////////////////////////////////////////////
// SEX
////////////////////////////////////////////////////////////////////////////////

export const FEMALE = 'F';
export const BOTH = 'B';
export const MALE = 'M';

export const SEX_LABELS: {
  [sexOptions in SexOption]: string;
} = {
  [BOTH]: 'Both',
  [FEMALE]: 'Female',
  [MALE]: 'Male',
};

export const commonSexName = {
  [BOTH]: {
    plural: 'girls or boys',
    singular: 'girl or boy',
  },
  [FEMALE]: {
    plural: 'girls',
    singluar: 'girl',
  },
  [MALE]: {
    plural: 'boys',
    singluar: 'boy',
  },
};

export const sexOptionsOrder = [FEMALE, BOTH, MALE];

export const sexNames = {
  [BOTH]: {
    plural: 'females or males',
    singular: 'female or male',
  },
  [FEMALE]: {
    plural: 'females',
    singular: 'female',
  },
  [MALE]: {
    plural: 'males',
    singular: 'male',
  },
};

////////////////////////////////////////////////////////////////////////////////
// SORTS
////////////////////////////////////////////////////////////////////////////////

export const createSort: () => Sort = () => ({
  calculation: 'max',
  direction: 'ascending',
  type: 'count',
});

const stringCompare = <T extends string>(a: T, b: T) => a.localeCompare(b);
// const stringCompareProperty = <T extends string>(object: { [t: T]: any }) => (a: T, b: T) => a.localeCompare(b);

export const calculationLabelsByCalculation: { [calculation in Calculation]: string } = {
  mad: 'Median Absolute Deviation', // float
  max: 'Maximum', // int
  mean: 'Average', // float
  median: 'Median', // int
  min: 'Minimum', // int
  std: 'Standard Deviation', // int
  sum: 'Sum', // int
  variance: 'Variance', // float
};

export const calculationLabels = Object.values(calculationLabelsByCalculation)
  .sort(stringCompare);

export const calculationOrder = Object.keys(calculationLabelsByCalculation)
  .sort(stringCompare) as readonly Calculation[];

export const calculationOrderSortedByLabels = [...calculationOrder]
  .sort((a, b) => calculationLabelsByCalculation[a].localeCompare(calculationLabelsByCalculation[b]));

export const statisticTypeLabelsByName: { [statisticType in StatisticType]: string } = {
  count: 'Count',
  rank: 'Rank',
};
export const statisticsLabels = Object.values(statisticTypeLabelsByName).sort(stringCompare);
export const statisticsOrder = Object.keys(statisticTypeLabelsByName).sort(stringCompare) as StatisticType[];

export const directionLabelsByDirection: { [name in Direction]: string } = {
  ascending: 'Ascending',
  descending: 'Descending',
};
export const directionLabels = Object.values(directionLabelsByDirection).sort(stringCompare);
export const directionOrder = Object.keys(directionLabelsByDirection).sort(stringCompare) as Direction[];


////////////////////////////////////////////////////////////////////////////////
// FILTERS
////////////////////////////////////////////////////////////////////////////////

export const subjectLabels: {
  [subject in FilterSubject]: string;
} = {
  ...statisticTypeLabelsByName,
  name: 'Name',
  sex: 'Sex',
};

export const filterTypeLabels: {
  [type in FilterType]: string;
} = {
  ...calculationLabelsByCalculation,
  contains: 'Contains',
  doesNotContain: 'Does Not Contain',
  endsWith: 'Ends With',
  length: 'Length',
  sex: 'Sex',
  startsWith: 'Starts With',
};

export const filterOperatorOrder: Operator[] = [
  '>',
  '>=',
  '=',
  '!=',
  '<=',
  '<',
];

export const filterConfigs: Filter[] = [
  {
    inputType: 'string',
    operator: '=',
    singleton: true,
    subject: 'sex',
    type: 'sex',
    value: null,
  },

  {
    inputType: 'string',
    operator: null,
    singleton: true,
    subject: 'name',
    type: 'startsWith',
    value: null,
  },
  {
    inputType: 'string',
    operator: null,
    singleton: false,
    subject: 'name',
    type: 'contains',
    value: null,
  },
  {
    inputType: 'string',
    operator: null,
    singleton: false,
    subject: 'name',
    type: 'doesNotContain',
    value: null,
  },
  {
    inputType: 'string',
    operator: null,
    singleton: true,
    subject: 'name',
    type: 'endsWith',
    value: null,
  },

  ...map(operator => ({
    inputType: 'number',
    operator,
    singleton: true,
    subject: 'name',
    type: 'length',
    value: null,
  } as Filter), filterOperatorOrder),

  ...chain(group => (
    chain(calculation => (
      filterOperatorOrder.flatMap((operator: Operator) => ({
        inputType: 'number',
        operator,
        singleton: true,
        subject: group,
        type: calculation,
        value: null,
      }) as Filter)
    ), calculationOrder)
  ), statisticsOrder),
];
export const filterSubjectOrder = uniq(pluck('subject', filterConfigs)).sort(stringCompare);

export const filterTypeOrder = (subject: FilterSubject): FilterType[] => pipe(
  (x: Filter[]) => filter(whereEq({ subject }), x),
  x => pluck('type', x),
  sort(stringCompare),
  uniq,
)(filterConfigs);

export const getBlankFilter = (type: FilterType) => find(whereEq({ type }), filterConfigs);

export const createFilter = (properties?: Pick<Filter, 'operator' | 'type' | 'subject' | 'value'>): Filter => {
  const fallback = find(whereEq({ type: 'contains' }), filterConfigs) as Filter;
  if (!properties) {
    return fallback;
  }

  const {
    operator,
    type,
    value,
    subject,
  } = properties;

  const addValue = (filter: Filter) => ({
    ...filter,
    value: value ?? (filter.inputType === 'string' ? '' : null),
  }) as Filter;

  const threeMatched = find(whereEq({ operator, subject, type }), filterConfigs);

  if (threeMatched) {
    return addValue(threeMatched);
  }

  const twoMatched = find(whereEq({ subject, type }), filterConfigs);
  if (twoMatched) {
    return addValue(twoMatched);
  }

  const oneMatched = find(whereEq({ subject }), filterConfigs);
  if (oneMatched) {
    return addValue(oneMatched);
  }

  return addValue(fallback);
};

////////////////////////////////////////////////////////////////////////////////
// Statistics
////////////////////////////////////////////////////////////////////////////////

export const databaseStatisticsLabels: {
  [key in keyof YearTotals]: string;
} = {
  femaleNames: 'Female Names',
  females: 'Females Born',
  maleNames: 'Male Names',
  males: 'Males Born',
  total: 'Total Born',
  totalNames: 'Total Names',
  year: 'Year',
};

////////////////////////////////////////////////////////////////////////////////
// Analytics
////////////////////////////////////////////////////////////////////////////////

export const analyticsExcludeList = [
  'database',
  'nameIds',
  'nameRecord',
  'nameRecords',
  'yearTotals',
];
