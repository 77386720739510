import { ApplicationState } from '..';
import { NameRecord, NameId } from 'common/entities';
import { selectSelectedNameIds } from '../selected/selected-selectors';
import { selectNamePoolNameIds } from '../search/search-selectors';

export const selectPreviewName = (store: ApplicationState) => store.previewState.previewName;
export const selectPreviewSex = (store: ApplicationState) => store.previewState.previewSex;
export const selectPreviewTab = (store: ApplicationState) => store.previewState.previewTab;
export const selectPreviewIsLoading = (store: ApplicationState) => store.previewState.isLoading;
export const selectPreviewModalVisible = (store: ApplicationState) => store.previewState.modalVisible;
export const selectPreviewOpenedFrom = (store: ApplicationState) => store.previewState.openedFrom;

export const selectPreviewNameIds = (store: ApplicationState): NameId[] => {
  const openedFrom = selectPreviewOpenedFrom(store);
  switch (openedFrom) {
    case 'pool':
      return selectNamePoolNameIds(store) ?? [];

    case 'selected':
      return selectSelectedNameIds(store);

    default:
      return [];
  }
};

export const selectNameIsPreviewed = (nameRecord: NameRecord | null) => ({
  previewState: { previewName, previewSex },
}: ApplicationState) => (nameRecord ? nameRecord.name === previewName && nameRecord.sex === previewSex : false);
