import React, { FC, useEffect, Fragment } from 'react';
import { mapIndexed } from 'ramda-adjunct';
import { Container, Button as MuiButton, Typography, Box, SvgIcon } from '@material-ui/core';
import { NameButton } from './NameButton';
import { NamesChart } from './NamesChart';
import { withStyles } from '@material-ui/styles';
import { DeleteForever, GetApp } from '@material-ui/icons';

import { getColors, getChartData, downloadFile } from '../tools/utils';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedNames, selectSelectedNameIds } from '../store/selected/selected-selectors';
import { isEmpty, without, join, pipe, map, prepend } from 'ramda';
import { selectYearRange } from '../store/config/config-selectors';
import { clearAllSelectedNamesAction } from '../store/selected/selected-actions';
import { NameRecord } from 'common/entities';
import { fetchNameRecordsAsync } from '../store/database/database-actions';
import { GreenCheckMark } from '../assets/icons';
import { ErrorAlert } from './ErrorBoundary';
import { selectDatabaseError } from '../store/database/database-selectors';
import { Alert, AlertTitle } from '@material-ui/lab';

const ChartButtons = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.5em 0 0 0',
    padding: 0,
  },
})(Container);

const Button = withStyles({
  root: {
    marginRight: 16,
  },
})(MuiButton);

const ButtonsWrapper = withStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
})(Box);

const Icon = () => (
  <SvgIcon
    style={{
      marginRight: '0.5em',
    }}
  >
    <GreenCheckMark />
  </SvgIcon>
);

interface ToolbarSectionProps {
  nameRecords: NameRecord[];
}

export const ToolbarSection: FC<ToolbarSectionProps> = ({ nameRecords }) => {
  const dispatch = useDispatch();
  const yearRange = useSelector(selectYearRange);
  const colors = getColors(nameRecords.length);
  return (
    <Fragment>
      <ButtonsWrapper>
        {mapIndexed(
          (nameRecord, index) => (
            <NameButton
              key={nameRecord.nameId}
              nameRecord={nameRecord}
              openedFrom="selected"
              style={{ borderColor: colors[index] }}
            />
          ),
          nameRecords,
        )}
      </ButtonsWrapper>

      <ChartButtons>
        {!isEmpty(nameRecords) && (
          <Button
            onClick={() => {
              dispatch(clearAllSelectedNamesAction());
            }}
            startIcon={<DeleteForever />}
            variant="text"
          >
            Clear All
          </Button>
        )}
        <Button
          onClick={event => {
            const chartData = getChartData(nameRecords, yearRange);
            let dataString = '';
            let extension = '';
            if (event?.ctrlKey) {
              dataString = JSON.stringify(chartData, null, 2);
              extension = 'json';
            } else {
              const keys = Object.keys(chartData[0]).sort();
              const keyOrder = [
                'year',
                ...without(['year'], keys),
              ];
              const makeRow = <T extends unknown>(columns: (keyof T)[]) => (data: T) => (
                map(column => data[column] ?? 0, columns)
              );

              extension = 'csv';
              dataString = pipe(
                map(makeRow(keyOrder)),
                map(join(',')),
                prepend(join(',', keyOrder)),
                join('\n'),
              )(chartData);
            }
            downloadFile(dataString, `chartData.${extension}`);
          }}
          startIcon={<GetApp />}
        >
          Download Chart Data
        </Button>
      </ChartButtons>
    </Fragment>
  );
};

export const SelectedNames: FC = () => {
  const dispatch = useDispatch();

  const nameRecords = useSelector(selectSelectedNames);
  const selectedNameIds = useSelector(selectSelectedNameIds);
  const databaseError = useSelector(selectDatabaseError);

  useEffect(() => {
    // TODO: This is a bit of a hack.  This should have its own saga..
    dispatch(fetchNameRecordsAsync.request({ nameIds: selectedNameIds }));
  }, [selectedNameIds, dispatch]);

  let content = null;
  if (databaseError) {
    content = <ErrorAlert message={databaseError.message} />;
  } else if (isEmpty(selectedNameIds)) {
    content = (
      <Alert severity="info" variant="outlined">
        <AlertTitle>Use the Name Pool to select a name</AlertTitle>
        Any name that you select will appear here in a single convenient graph!
      </Alert>
    );
  } else {
    console.log({ nameRecords });
    content = (
      <Fragment>
        <ToolbarSection nameRecords={nameRecords} />
        <NamesChart nameRecords={nameRecords} />
      </Fragment>
    );
  }


  return (
    <Fragment>
      <Typography variant="h3">
        <Icon />
        Selected Names
      </Typography>

      {content}
    </Fragment>
  );
};
