import { call, put, select } from 'redux-saga/effects';
import { fetchSearchAsyncAction } from './search-actions';
import { callApi, DATABASE_API } from '../../tools/utils';
import { selectLimit, selectFilters, selectSorts } from './search-selectors';
import { selectYearRange } from '../config/config-selectors';
import { Filter, YearRange, Sort, NameId, NameRecord } from 'common/entities';
import { difference } from 'ramda';
import { selectDatabaseNameIds } from '../database/database-selectors';
import { fetchNameRecordsAsync } from '../database/database-actions';

export function* searchSaga() {
  const filters: Filter[] = yield select(selectFilters);
  const limit: number = yield select(selectLimit);
  const sorts: Sort[] = yield select(selectSorts);
  const yearRange: YearRange = yield select(selectYearRange);
  try {
    const result: ReturnType<typeof fetchSearchAsyncAction.success>['payload'] = yield call(
      callApi,
      'POST',
      `${DATABASE_API}/search`,
      {
        filters,
        limit,
        sorts,
        yearRange,
      } as unknown as BodyInit,
    );
    yield put(fetchSearchAsyncAction.success(result));
  } catch (error) {
    yield put(fetchSearchAsyncAction.failure(error));
  }
}

export function* requestSearchSaga() {
  yield put(fetchSearchAsyncAction.request());
}

export function* searchDatabaseSaga(action: ReturnType<typeof fetchSearchAsyncAction.success>) {
  const { payload: { nameIds } } = action;
  const databaseNameIds: NameId[] = yield select(selectDatabaseNameIds);
  const missingNameIds = difference(nameIds, databaseNameIds);

  if (missingNameIds.length > 0) {
    try {
      const { nameRecords }: { nameRecords: NameRecord[] } = yield call(
        callApi,
        'POST',
        `${DATABASE_API}/byNameIds`,
        { nameIds } as unknown as BodyInit,
      );
      yield put(fetchNameRecordsAsync.success({ nameRecords }));
    } catch (error) {
      yield put(fetchNameRecordsAsync.failure(error));
    }
  }
}
