import { omit, isEmpty, pipe, toPairs, map, mergeAll, without } from 'ramda';
import { analyticsExcludeList } from 'common/data';
import { analytics as firebaseAnalytics } from 'firebase';
import { Middleware } from 'redux';

/* eslint-disable @typescript-eslint/no-unsafe-return */
const actionPayloadCleaner = (action: unknown) => {
  const { payload, type } = action as any;
  if (!payload) {
    return {};
  }
  if (typeof payload !== 'object' || Array.isArray(payload)) {
    return { payload };
  }
  let excludeList = analyticsExcludeList;
  if (payload.nameIds && type !== 'database/FETCH_SEARCH_SUCCESS') {
    excludeList = without(['nameIds'], excludeList);
  }

  let clean = omit(excludeList, payload) as any;
  if (isEmpty(clean)) {
    return {};
  }

  if (clean?.filter) {
    const { filter, ...rest } = clean;
    const filtered = pipe(
      // @ts-expect-error ramda
      toPairs,
      map(([key, value]) => ({
        [`filter.${key}`]: value,
      })),
      mergeAll,
      // @ts-expect-error ramda
    )(filter);

    clean = {
      ...rest,
      // @ts-expect-error ramda
      ...filtered,
    };
  }

  if (clean?.sort) {
    const { sort, ...rest } = clean;
    const sorted = pipe(
      // @ts-expect-error ramda
      toPairs,
      map(([key, value]) => ({
        [`sort.${key}`]: value,
      })),
      mergeAll,
      // @ts-expect-error ramda
    )(sort);

    clean = {
      ...rest,
      // @ts-expect-error ramda
      ...sorted,
    };
  }

  return clean;
};

export const analyticsMiddleware = (analytics: firebaseAnalytics.Analytics): Middleware => (
  () => next => action => {
    const fieldsObject = {
      ...(action?.meta ? action.meta : {}),
      ...actionPayloadCleaner(action),
    };

    const params = {
      category: 'redux',
      ...fieldsObject,
    };
    analytics.logEvent(action.type, params);

    return next(action);
  }
);
/* eslint-enable @typescript-eslint/no-unsafe-return */
