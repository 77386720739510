import React, { FC, useState } from 'react';
import { Typography, Box, CircularProgress, Card, Grid, SvgIcon, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { map } from 'ramda';
import { withStyles, CSSProperties } from '@material-ui/styles';
import { NameButton } from './NameButton';
import { ArrowDownward } from '@material-ui/icons';
import { Filters } from './Filters';
import { Sorts } from './Sorts';
import { YearRange } from './YearRange';
import {
  selectDatabaseYearBounds,
  selectTotalNameRecordsCount,
} from '../store/database/database-selectors';
import { selectExpertMode, selectYearRange, selectShowDatabaseStatistics } from '../store/config/config-selectors';
import { useSelector } from 'react-redux';
import {
  selectFiltersError,
  selectFiltersIsLoading,
  selectMatchCount,
  selectNamePool,
  selectYearRangeCount,
} from '../store/search/search-selectors';
import { primaryColor } from '../tools/theme';
import { DatabaseStatistics } from './DatabaseStatistics';
import { AmericasGlobe } from '../assets/icons';
import { ErrorAlert } from './ErrorBoundary';

const Highlight = withStyles({
  root: {
    color: 'black',
    display: 'inline',
    fontSize: '12px',
    fontWeight: 600,
  },
})(Typography);

const Icon = () => (
  <SvgIcon
    style={{
      marginRight: '0.5em',
    }}
  >
    <AmericasGlobe />
  </SvgIcon>
);

const ProgressWrapper = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2em',
  },
})(Box);

const SearchArea = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: 12,
  },
})(Box);

interface DataBarProps {
  minimal: boolean;
  percentageText: string;
  value: number | null;
}

const DataBar: FC<DataBarProps> = ({ children, minimal, percentageText, value }) => {
  const smallScreenWidth = useMediaQuery(useTheme().breakpoints.down('xs'));

  const expertMode = useSelector(selectExpertMode);
  if (!expertMode) {
    return null;
  }

  const explainText = ` ${percentageText}`;
  const percentage = value === null ? null : (
    `${Math.floor((value * 100)) - (value * 100) < 0 ? (value * 100).toFixed(1) : (value * 100)}%${explainText}`
  );

  const textWrapper: CSSProperties = {
    color: '#666666',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 6px',
  };

  const wrapperStyle: CSSProperties = {
    backgroundColor: '#ededed',
    width: '100%',
  };

  const style: CSSProperties = {
    backgroundColor: primaryColor,
    height: 4,
    width: `${value === null ? 0 : (value + 0.005) * 100}%`,
  };

  return (
    <Grid item xs={12}>
      <div className="thing">
        {!minimal && (
          <div style={textWrapper}>
            <div style={{ marginRight: 12 }}>{children}</div>
            {smallScreenWidth && <Divider flexItem orientation="vertical" />}
            <div style={{ marginLeft: 12 }}>{percentage}</div>
          </div>
        )}

        <div style={wrapperStyle}>
          <div style={style} />
        </div>
      </div>
    </Grid>
  );
};

const ArrowGridItem = withStyles({
  root: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
    marginBottom: -6,
    marginTop: 2,
  },
})(Grid);

const FilterArrow: FC = () => (
  <ArrowGridItem item xs={12}>
    <ArrowDownward
      style={{
        color: '#d0d0d0',
        zIndex: -100,
      }}
    />
  </ArrowGridItem>
);

interface ItemProps {
  dataBar: (minimal: boolean) => JSX.Element | null;
  defaultExpanded?: boolean;
  render: (x: { expanded: boolean; toggleExpanded: () => void }) => void;
  tutorial: string;
}
const Item: FC<ItemProps> = ({ dataBar, defaultExpanded, render, tutorial }) => {
  const defaultExpandedValue = defaultExpanded === undefined ? true : defaultExpanded;
  const [expanded, setExpanded] = useState(defaultExpandedValue);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid className={`tutorial__NamePool-${tutorial}`} item xs={12}>
      <Card>
        {render({ expanded, toggleExpanded })}
        {dataBar(!expanded)}
      </Card>
      <FilterArrow />
    </Grid>
  );
};

export const NamePool: FC = () => {
  const databaseIsLoading = useSelector(selectFiltersIsLoading);
  const totalNameRecordsCount = useSelector(selectTotalNameRecordsCount);
  const namePool = useSelector(selectNamePool);
  const matchCount = useSelector(selectMatchCount);
  const [startYearBound, endYearBound] = useSelector(selectDatabaseYearBounds);
  const [startYear, endYear] = useSelector(selectYearRange);
  const yearRangeCount = useSelector(selectYearRangeCount);
  const error = useSelector(selectFiltersError);

  let renderError = null;
  if (!databaseIsLoading && matchCount === null && error !== null) {
    renderError = <ErrorAlert message={error.message} />;
  }

  const showDataseStatistics = useSelector(selectShowDatabaseStatistics);

  const buttons = map(
    nameRecord => (
      <NameButton
        key={nameRecord.nameId}
        nameRecord={nameRecord}
        openedFrom="pool"
      />
    ),
    namePool,
  );

  const databaseStatisticsDataBar = (minimal: boolean) => {
    if (databaseIsLoading || totalNameRecordsCount === null) {
      return null;
    }
    return (
      <DataBar
        minimal={minimal}
        percentageText="of all names"
        value={1}
      >
        <Highlight>{totalNameRecordsCount.toLocaleString()}</Highlight>{' '}
        total names from {startYearBound} to {endYearBound}
      </DataBar>
    );
  };

  const yearDataBar = (minimal: boolean) => {
    if (databaseIsLoading || totalNameRecordsCount === null || yearRangeCount === null) {
      return null;
    }
    return (
      <DataBar
        minimal={minimal}
        percentageText="of all names"
        value={yearRangeCount / totalNameRecordsCount}
      >
        <Highlight>{yearRangeCount.toLocaleString()}</Highlight>{' '}
        names after restricting years to {startYear}&nbsp;-&nbsp;{endYear}
      </DataBar>
    );
  };

  const filterDataBar = (minimal: boolean) => {
    if (databaseIsLoading || yearRangeCount === null || matchCount === null) {
      return null;
    }
    return (
      <DataBar
        minimal={minimal}
        percentageText={`of names ${startYear} - ${endYear}`}
        value={matchCount / yearRangeCount}
      >
        <Highlight>{matchCount.toLocaleString()}</Highlight> names after applying filters
      </DataBar>
    );
  };

  const count = namePool.length;
  const sortsDataBar = (minimal: boolean) => {
    if (databaseIsLoading || matchCount === null || count === null) {
      return null;
    }
    return (
      <DataBar
        minimal={minimal}
        percentageText="of filtered names"
        value={count / matchCount}
      >
        showing first <Highlight>{count.toLocaleString()}</Highlight> names after sorting
      </DataBar>
    );
  };

  const remainder = (matchCount ?? 0) - count;

  return (
    <div className="tutorial__NamePool-wrapper">
      <Typography className="tutorial__NamePool-header" variant="h3">
        <Icon />
        Name Pool
      </Typography>

      {renderError}

      {!renderError && (
        <SearchArea>
          <Grid container justify="flex-start" spacing={1}>
            {showDataseStatistics && (
              <Item
                dataBar={databaseStatisticsDataBar}
                render={({ expanded, toggleExpanded }) => (
                  <DatabaseStatistics
                    expanded={expanded}
                    toggleExpanded={toggleExpanded}
                  />
                )}
                tutorial="statistics"
              />
            )}

            <Item
              dataBar={yearDataBar}
              render={({ expanded, toggleExpanded }) => (
                <YearRange
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                />
              )}
              tutorial="years"
            />


            <Item
              dataBar={filterDataBar}
              render={({ expanded, toggleExpanded }) => (
                <Filters
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                />
              )}
              tutorial="filters"
            />


            <Item
              dataBar={sortsDataBar}
              render={({ expanded, toggleExpanded }) => (
                <Sorts
                  expanded={expanded}
                  toggleExpanded={toggleExpanded}
                />
              )}
              tutorial="sorts"
            />

          </Grid>
        </SearchArea>
      )}

      {databaseIsLoading && (
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
      )}

      {!databaseIsLoading && (
        <div className="tutorial__NamePool-pool">
          {buttons}

          {remainder > 0 && (
            <div>
              ...and <Highlight>{remainder.toLocaleString()}</Highlight> more
            </div>
          )}
        </div>
      )}
    </div>
  );
};
