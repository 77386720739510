import { NameId, SexOption, NameRecord, OpenedFrom } from 'common/entities';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const changePreviewTabAction = createAction('preview/CHANGE_PREVIEW_TAB')<SexOption>();

export const closePreviewModalAction = createAction('preview/CLOSE_MODAL')();

export const fetchPreviewNameAsyncAction = createAsyncAction(
  'database/FETCH_PREVIEW_NAME_REQUEST',
  'database/FETCH_PREVIEW_NAME_SUCCESS',
  'database/FETCH_PREVIEW_NAME_FAILURE',
)<{ nameId: NameId; openedFrom: OpenedFrom }, { nameRecords: NameRecord[] }, Error>();
