import React, { FC, useCallback, MouseEventHandler, useState } from 'react';
import { Button as MuiButton, Badge as MuiBadge } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ButtonProps } from '@material-ui/core/Button';
import { popularity } from '../tools/utils';
import { NameRecord, NameId, OpenedFrom } from 'common/entities';
import { MALE } from 'common/data';
import { tertiaryColor } from '../tools/theme';
import { adjustHue } from 'polished';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectLastName,
  selectYearRange,
  selectExpertMode,
} from '../store/config/config-selectors';
import { selectNameIsPreviewed } from '../store/preview/preview-selectors';
import { fetchPreviewNameAsyncAction } from '../store/preview/preview-actions';
import { calculateOneStatistic } from 'common/statistics';
import { selectNameIsSelected } from '../store/selected/selected-selectors';
import { selectNameAction, unselectNameAction } from '../store/selected/selected-actions';
import { useLongPress, LongPressCallback, LongPressDetectEvents } from 'use-long-press';
import { useWindowScroll } from 'react-use';

const margin = '0.25em';

const Badge = withStyles({
  anchorOriginTopRightRectangle: {
    fontSize: '0.6em',
    right: 0,
    top: 0,
    transform: 'none',
    transformOrigin: '0%',
  },
  badge: {
    borderRadius: 1,
    height: 12,
    minWidth: 10,
    width: 10,
  },
})(MuiBadge);

const DataBar: FC<{ height: number }> = props => (
  <div
    style={{
      backgroundColor: adjustHue(props.height * 210, tertiaryColor),
      bottom: 0,
      height: `${props.height * 100}%`,
      left: 0,
      position: 'absolute',
      width: 3,
    }}
    {...props}
  />
);

const Button = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin,
    overflow: 'hidden',
    position: 'relative',
  },
})(MuiButton);

interface Props {
  nameRecord: NameRecord;
  openedFrom: OpenedFrom;
}

interface UseLongPressResult {
  openedFrom: OpenedFrom;
  nameId: NameId;
}

const useLongPressResult = ({ nameId, openedFrom }: UseLongPressResult) => {
  const dispatch = useDispatch();
  const isSelected = useSelector(selectNameIsSelected(nameId));

  const { y: currentScrollHeight } = useWindowScroll();
  const [scrollState, setScrollState] = useState<number | null>(null);

  const onLongPress = useCallback<LongPressCallback<HTMLButtonElement>>(() => {
    if (currentScrollHeight !== scrollState) {
      setScrollState(null);
      return;
    }
    setScrollState(null);

    if (isSelected) {
      dispatch(unselectNameAction(nameId));
    } else {
      dispatch(selectNameAction(nameId));
    }
  }, [dispatch, isSelected, nameId, scrollState, currentScrollHeight]);

  const onContextMenu: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
  };

  /** onCancel, in this context with onLongPress, serves as onClick on the desktop */
  const onCancel = useCallback<LongPressCallback<HTMLButtonElement>>(event => {
    if (currentScrollHeight !== scrollState) {
      setScrollState(null);
      return;
    }
    setScrollState(null);

    if (event?.ctrlKey) {
      if (isSelected) {
        dispatch(unselectNameAction(nameId));
      } else {
        dispatch(selectNameAction(nameId));
      }
      return;
    }
    dispatch(fetchPreviewNameAsyncAction.request({ nameId, openedFrom }));
  }, [
    dispatch,
    isSelected,
    nameId,
    currentScrollHeight,
    openedFrom,
    scrollState,
  ]);

  const onStart = useCallback<LongPressCallback<HTMLButtonElement>>(() => {
    setScrollState(currentScrollHeight);
  }, [currentScrollHeight]);

  const onFinish = useCallback<LongPressCallback<HTMLButtonElement>>(() => {
    setScrollState(null);
  }, []);

  const longPressResult = useLongPress<HTMLButtonElement, LongPressCallback<HTMLButtonElement>>(onLongPress, {
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH,
    onCancel,
    onFinish,
    onStart,
    threshold: 500,
  });

  return {
    ...longPressResult,
    onContextMenu,
  };
};

const UnStyledButton: FC<Props & ButtonProps> = ({ nameRecord, openedFrom, ...rest }) => {
  const { name, nameId, sex } = nameRecord;

  const lastName = useSelector(selectLastName);
  const isPreviewed = useSelector(selectNameIsPreviewed(nameRecord));

  const yearRange = useSelector(selectYearRange);
  const expertMode = useSelector(selectExpertMode);
  const longPressResult = useLongPressResult({ nameId, openedFrom });
  const mean = calculateOneStatistic(yearRange, 'rank', 'mean')(nameRecord) ?? 0;

  return (
    <Badge
      badgeContent={sex}
      color={sex === MALE ? 'primary' : 'secondary'}
      invisible={!expertMode}
    >
      <Button
        key={nameId}
        size="small"
        variant={isPreviewed ? 'contained' : 'outlined'}
        {...longPressResult}
        {...rest}
      >
        {expertMode && <DataBar height={popularity(mean)} />}
        {name}{lastName && ` ${lastName}`}
      </Button>
    </Badge>
  );
};

export const NameButton = withStyles({
  root: {
    margin: '0.25em',
    paddingLeft: 14,
    paddingRight: 14,
  },
})(UnStyledButton);
