import { Name, SexOption, Sex, OpenedFrom } from 'common/entities';
import { changePreviewTabAction, closePreviewModalAction, fetchPreviewNameAsyncAction } from './preview-actions';
import { createReducer } from 'typesafe-actions';
import { selectNameAction, unselectNameAction } from '../selected/selected-actions';
import { BOTH } from 'common/data';
import { getNameFromNameId, getSexFromNameId } from 'common/utils';

export interface PreviewState {
  error: Error | null;
  isLoading: boolean;
  modalVisible: boolean;
  openedFrom: OpenedFrom | null;
  previewName: Name | null;
  previewSex: Sex | null;
  previewTab: SexOption;
}

const initialState: PreviewState = {
  error: null,
  isLoading: false,
  modalVisible: false,
  openedFrom: null,
  previewName: null,
  previewSex: null,
  previewTab: BOTH,
};

export const previewReducer = createReducer(initialState)
  .handleAction(fetchPreviewNameAsyncAction.request, (state, action) => ({
    ...state,
    error: null,
    isLoading: true,
    modalVisible: true,
    openedFrom: action.payload.openedFrom,
    previewName: action.payload.nameId === null ? null : getNameFromNameId(action.payload.nameId),
    previewSex: action.payload.nameId === null ? null : getSexFromNameId(action.payload.nameId),
    previewTab: action.payload.nameId === null ? BOTH : getSexFromNameId(action.payload.nameId),
  }))
  .handleAction(fetchPreviewNameAsyncAction.success, state => ({
    ...state,
    isLoading: false,
  }))
  .handleAction(fetchPreviewNameAsyncAction.failure, (state, action) => ({
    ...state,
    error: action.payload ?? null,
    isLoading: false,
    openedFrom: null,
  }))
  .handleAction([selectNameAction, unselectNameAction, closePreviewModalAction], state => ({
    ...state,
    error: null,
    isLoading: false,
    modalVisible: false,
    openedFrom: null,
    previewName: null,
    previewSex: null,
    previewTab: BOTH,
  }))
  .handleAction(changePreviewTabAction, (state, action) => ({
    ...state,
    previewTab: action.payload,
  }));
