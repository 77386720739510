import { createReducer } from 'typesafe-actions';
import { startTourAction } from './tour-actions';
import { Step } from 'react-joyride';

export interface TourState {
  run: boolean;
  steps: Step[];
}

const initialState: TourState = {
  run: true,
  steps: [
    {
      content: 'The Name Pool is where you will narrow down the 110,356 total names ever given in the US since 1880 to a smaller set that you are interested in.',
      locale: {
        open: 'learn more about the Name Pool',
      },
      placementBeacon: 'bottom',
      target: '.tutorial__NamePool-header',
      title: 'Name Pool',
    },
    {
      content: 'You can filter the results to a certain year range.',
      locale: {
        open: 'learn about year filtering',
      },
      placementBeacon: 'left',
      spotlightPadding: 0,
      target: '.tutorial__NamePool-years',
      title: 'Filter By Years',
    },
    {
      content: 'You can filter the names themselves based on their charestics or stats.',
      locale: {
        open: 'learn about name filtering',
      },
      placementBeacon: 'left',
      spotlightPadding: 0,
      target: '.tutorial__NamePool-filters',
      title: 'Filter by Name/Rank/Count',
    },
    {
      content: 'Click this button to add a new filter.',
      locale: {
        open: 'learn how to add filters',
      },
      placementBeacon: 'left',
      spotlightClicks: true,
      spotlightPadding: 0,
      target: '.tutorial__NamePool-addFilter',
      title: 'Try Adding a New Filter',
    },
    {
      content: 'You can click this button to add new filters.',
      locale: {
        open: 'learn how to add filters',
      },
      placementBeacon: 'left',
      spotlightPadding: 0,
      target: '.tutorial__FilterField:last-child',
      title: 'Add New Filters',
    },
    {
      content: 'You can sort the filtered names by rank or count.',
      locale: {
        open: 'learn about sorting',
      },
      placementBeacon: 'left',
      spotlightPadding: 0,
      target: '.tutorial__NamePool-sorts',
      title: 'Sort results by Count or Rank',
    },
    {
      content: 'After all of the above filtering, you can click on any name here and add as one of your selected names.',
      disableOverlayClose: true,
      locale: {
        open: 'learn how to select names',
      },
      placementBeacon: 'top',
      target: '.tutorial__NamePool-pool',
      title: 'View Results in the Pool',
    },
  ],
};

export const tourReducer = createReducer(initialState)
  .handleAction(startTourAction, state => ({
    ...state,
    run: true,
  }));
