import React, { FC, Fragment, useRef } from 'react';
import { Name } from 'common/entities';
import {
  Typography,
  Switch,
  Input as MuiInput,
  SvgIcon,
  List,
  ListItemText,
  ListItemIcon,
  ListItem as MuiListItem,
  ListItemSecondaryAction,
  Theme,
  Box,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectLastName, selectExpertMode, selectShowDatabaseStatistics } from '../store/config/config-selectors';
import { changeLastNameAction, setExpertModeAction, setShowDatabaseStatistics } from '../store/config/config-actions';
import { Wrench } from '../assets/icons';
import { EmojiObjects, Equalizer, EmojiPeople } from '@material-ui/icons';

const ListItem = withStyles({
  secondaryAction: {
    paddingRight: 72,
  },
})(MuiListItem);

const ListItemSecondaryActionWrapping = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginLeft: 56,
      marginTop: 4,
      paddingRight: 16,
      position: 'relative',
    },
  },
}))(ListItemSecondaryAction);

const Input = withStyles({
  root: {
    marginLeft: '2em',
  },
})(MuiInput);

const Icon = () => (
  <SvgIcon
    style={{
      marginRight: '0.5em',
    }}
  >
    <Wrench />
  </SvgIcon>
);

const Wrapper = withStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.sm,
  },
}))(Box);

export const ConfigArea: FC = () => {
  const lastName = useSelector(selectLastName);
  const dispatch = useDispatch();
  const setLastName = (name: Name) => {
    dispatch(changeLastNameAction(name));
  };
  const expertMode = useSelector(selectExpertMode);
  const showDatabaseStatistics = useSelector(selectShowDatabaseStatistics);
  const inputRef = useRef<HTMLInputElement>();

  return (
    <Fragment>
      <Typography variant="h3">
        <Icon />
        Configuration
      </Typography>

      <Wrapper>
        <List>
          <ListItem
            button
            onClick={() => {
              dispatch(setExpertModeAction(!expertMode));
            }}
          >
            <ListItemIcon><EmojiObjects /></ListItemIcon>
            <ListItemText
              primary="Expert Mode"
              secondary="Enables advanced features, graphics, and charts"
            />
            <ListItemSecondaryAction>
              <Switch
                checked={expertMode}
                onChange={event => {
                  dispatch(setExpertModeAction(event.target.checked));
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem
            button
            onClick={() => {
              dispatch(setShowDatabaseStatistics(!showDatabaseStatistics));
            }}
          >
            <ListItemIcon><Equalizer /></ListItemIcon>
            <ListItemText
              primary="Show Database Statistics"
              secondary="Enables a special Database Statistics section"
            />
            <ListItemSecondaryAction>
              <Switch
                checked={showDatabaseStatistics}
                onChange={event => {
                  dispatch(setShowDatabaseStatistics(event.target.checked));
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>


          <ListItem
            button
            onClick={() => {
              inputRef.current?.focus();
            }}
          >
            <ListItemIcon><EmojiPeople /></ListItemIcon>
            <ListItemText
              primary="Last Name"
              secondary="Adds a last name for every name button"
            />
            <ListItemSecondaryActionWrapping>
              <Input
                inputRef={inputRef}
                onChange={event => {
                  setLastName(event.target.value);
                }}
                value={lastName}
              />
            </ListItemSecondaryActionWrapping>
          </ListItem>
        </List>
      </Wrapper>
    </Fragment>
  );
};
