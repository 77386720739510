import { ApplicationState } from '..';

export const selectLastName = (store: ApplicationState) => store.configState.lastName;

export const selectYearRange = (store: ApplicationState) => store.configState.yearRange;

export const selectYearDefaults = (store: ApplicationState) => store.configState.yearDefaults;

export const selectExpertMode = (store: ApplicationState) => store.configState.expertMode;

export const selectShowDatabaseStatistics = (store: ApplicationState) => store.configState.showDatabaseStatistics;
