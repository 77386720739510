import red from '@material-ui/core/colors/red';
import { createMuiTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { transparentize } from 'polished';

/**
 * Palette URL: http://paletton.com/#uid=74w1q0kewKQ4GYG9QSUiCAHm3uK
 */
export const primaryPurple = ['#A67CD5', '#E7D8F9', '#C7A8EA', '#8657BB', '#6E3DA6'];

export const primaryYellow = ['#FFB18B', '#FFE6DA', '#FFCAB1', '#FF9B6B', '#F5834C'];

export const primaryGreen = ['#74D5B3', '#D5F9EC', '#A2EAD0', '#4EBA94', '#33A47C'];

export const primaryOrange = ['#FFF78B', '#FFFCDA', '#FFF9B1', '#FFF46B', '#F5E94C'];

export const primaryColor = '#8884d8';
export const secondaryColor = '#d8b284';
export const tertiaryColor = '#84d89d';
export const fourthColor = '#d88495';

/** used for the `theme-color` meta-tag @see index.html */
export const slightlyDarkerPrimaryColor = '#7571d2';

// A custom theme for this app
const theme = createMuiTheme({
  overrides: {
    // @ts-expect-error no time to figure out how to do module augmentation with create-react-app https://material-ui.com/components/about-the-lab/#typescript
    MuiAlert: {
      root: {
        // borderRadius: 'none',
      },
    },
    MuiButton: {
      root: {
        // borderRadius: 'none',
        textTransform: 'none',
      },
    },
    MuiTypography: {
      h3: {
        alignItems: 'center',
        borderBottom: `1px solid ${transparentize(0.3, primaryColor)}`,
        color: grey[700],
        display: 'flex',
        fontSize: '1.75rem',
        lineHeight: '1.5em',
        marginBottom: '0.5em',
        marginTop: '1em',
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
    },
    error: {
      main: red.A400,
    },
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  props: {
    MuiPaper: {
      // square: true,
    },
  },
});

export default theme;
