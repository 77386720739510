import React, { FC, useEffect } from 'react';
import { BrowserRouter as Router, Route, useLocation, RouteComponentProps, useHistory } from 'react-router-dom';
import { App } from '../components/App';
import { useSelector, useDispatch } from 'react-redux';
import { selectYearRange } from '../store/config/config-selectors';
import { fetchDatabaseStatsAsync } from '../store/database/database-actions';
import { fetchSearchAsyncAction } from '../store/search/search-actions';
import { selectQueryParams } from '../store/store-selectors';
import { changeLastNameAction, changeYearRangeAction, setExpertModeAction } from '../store/config/config-actions';
import { selectNamesAction } from '../store/selected/selected-actions';

const useQuery = () => new URLSearchParams(useLocation().search);

const useLoadFromQuery = () => {
  const dispatch = useDispatch();
  const lastName = useQuery().get('lastName') ?? '';
  const names = useQuery().get('names') ?? null;
  const from = useQuery().get('from');
  const to = useQuery().get('to');
  const expertMode = useQuery().get('expertMode');
  const [startYear, endYear] = useSelector(selectYearRange);

  useEffect(() => {
    if (lastName.length > 0) {
      dispatch(changeLastNameAction(lastName));
    }

    if (names !== null) {
      dispatch(selectNamesAction(decodeURIComponent(names).split(',')));
    }

    if (from !== null && Number(from) !== startYear && Number(from) <= endYear) {
      dispatch(changeYearRangeAction([Number(from), endYear]));
    }

    if (to !== null && Number(to) !== endYear && Number(to) >= startYear) {
      dispatch(changeYearRangeAction([startYear, Number(to)]));
    }

    if (expertMode) {
      dispatch(setExpertModeAction(true));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

const useInit = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchDatabaseStatsAsync.request());
    dispatch(fetchSearchAsyncAction.request());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useLoadFromQuery();
};

const Middleman: FC<RouteComponentProps> = () => {
  const newQuery = useSelector(selectQueryParams);

  const history = useHistory();
  const { search } = useLocation();
  useEffect(() => {
    if (search !== newQuery) {
      history.replace({ search: newQuery });
    }
  }, [history, newQuery, search]);

  useInit();

  return <App />;
};

export const Routes: FC = () => (
  <Router>
    <Route component={Middleman} to="/" />
  </Router>
);
