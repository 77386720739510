import React, { FC } from 'react';
import { Grid, Container, withStyles, Theme } from '@material-ui/core';
import { SelectedNames } from './SelectedNames';
import { NamePool } from './NamePool';
import { TopBar } from './TopBar';
import { PreviewNameDialog } from './PreviewNameDialog';
import { Footer } from './Footer';
import { ConfigArea } from './ConfigArea';
import { Tour } from './Tour';
import '../index.css';

/*
?lastName=Mitropoulos&names=Dimitrios-M%2CKonstantinos-M%2CAlexandros-M%2CTheodoros-M%2CStavros-M%2CYiannis-M

?lastName=Ben-Ezra&names=Kevin-M%2CDavid-M%2CMelanie-F%2CNoa-F

?lastName=Papaya&names=Mary-F%2CCamren-M%2CZak-M%2CEloisa-F%2CDelilah-F

?lastName=Phillips&names=Mark-M%2CEdward-M%2CNancy-F%2CMarlene-F%2CWilliam-M%2CElizabeth-F
*/

const AppContainer = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    overflow: 'hidden',
  },
}))(Container);

export const App: FC = () => (
  <div style={{ overflow: 'auto' }}>
    <TopBar />
    <PreviewNameDialog />

    <AppContainer maxWidth="xl">
      <Grid
        alignItems="flex-start"
        container
        direction="row"
        justify="center"
        spacing={3}
        wrap="wrap"
      >
        <Tour />
        <Grid item lg={6} xs={12}>
          <Container maxWidth="lg">
            <NamePool />
          </Container>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Container maxWidth="lg">
            <Grid container direction="column">
              <Grid item xs={12}><SelectedNames /></Grid>
              <Grid item xs={12}><ConfigArea /></Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </AppContainer>

    <Footer />
  </div>
);
