import { createReducer } from 'typesafe-actions';
import { addFilterAction, removeFilterAction, modifyFilterAction, fetchSearchAsyncAction, setLimitAction, modifySortAction, addSortAction, removeSortAction } from './search-actions';
import { omitIndexes } from 'ramda-adjunct';
import { update, filter, whereEq, map, anyPass } from 'ramda';
import { Filter, NameId, Sort } from 'common/entities';
import { filterConfigs } from 'common/data';

export interface FilterState {
  error: Error | null;
  filters: Filter[];
  isLoading: boolean;
  limit: number;
  matchCount: number | null;
  nameIds: NameId[] | null;
  sorts: Sort[];
  yearRangeCount: number | null;
}

const startingFilters: Partial<Filter>[] = [
  { subject: 'sex' },
  { subject: 'name', type: 'startsWith' },
  { subject: 'name', type: 'contains' },
  { subject: 'name', type: 'endsWith' },
];

const initialState: FilterState = {
  error: null,
  filters: filter(
    anyPass(map(whereEq, startingFilters)),
    filterConfigs,
  ),
  isLoading: false,
  limit: 100,
  matchCount: 0,
  nameIds: null,
  sorts: [
    {
      calculation: 'mean',
      direction: 'ascending',
      type: 'rank',
    },
  ],
  yearRangeCount: 0,
};

export const searchReducer = createReducer(initialState)
  .handleAction(setLimitAction, (state, { payload }) => ({
    ...state,
    limit: payload,
  }))

  // Filter Actions
  .handleAction(addFilterAction, (state, { payload }) => ({
    ...state,
    filters: [...state.filters, payload],
  }))
  .handleAction(removeFilterAction, (state, { payload }) => ({
    ...state,
    filters: omitIndexes([payload], state.filters),
  }))
  .handleAction(modifyFilterAction, (state, { payload: { index, filter } }) => ({
    ...state,
    filters: update(index, filter, state.filters),
  }))

  // Sort Actions
  .handleAction(addSortAction, (state, { payload }) => ({
    ...state,
    sorts: [...state.sorts, payload],
  }))
  .handleAction(removeSortAction, (state, { payload }) => ({
    ...state,
    sorts: omitIndexes([payload], state.sorts),
  }))
  .handleAction(modifySortAction, (state, { payload: { index, sort } }) => ({
    ...state,
    sorts: update(index, sort, state.sorts),
  }))

  // Search Actions
  .handleAction(fetchSearchAsyncAction.request, state => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(fetchSearchAsyncAction.success, (state, { payload }) => ({
    ...state,
    error: null,
    isLoading: false,
    matchCount: payload.matchCount,
    nameIds: payload.nameIds,
    yearRangeCount: payload.yearRangeCount,
  }))
  .handleAction(fetchSearchAsyncAction.failure, (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
    matchCount: null,
    nameIds: null,
    yearRangeCount: null,
  }));
