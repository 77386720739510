import React, { FC } from 'react';
import { SvgIcon } from '@material-ui/core';
import { Chicken } from '../assets/icons';
import { Alert, AlertTitle } from '@material-ui/lab';

export const ErrorIcon: FC = () => (
  <SvgIcon>
    <Chicken />
  </SvgIcon>
);

export const ErrorAlert: FC<{ message: string }> = ({ message }) => (
  <Alert
    onContextMenu={event => {
      event.preventDefault();
    }}
    severity="error"
  >
    <AlertTitle>Error</AlertTitle>
    {message}
  </Alert>
);
