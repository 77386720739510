import React, { FC, MouseEventHandler } from 'react';
import { Theme, withStyles, IconButton as MuiIconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const IconButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[500],
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))(MuiIconButton);

interface CloseButtonProps {
  onClose: MouseEventHandler<HTMLButtonElement>;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClose }) => (
  <IconButton aria-label="close" onClick={onClose}>
    <Close />
  </IconButton>
);
