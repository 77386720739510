import React, { FC, useState } from 'react';
import {
  AppBar,
  Button as MuiButton,
  Container,
  IconButton as MuiIconButton,
  Snackbar,
  SvgIcon as MuiSvgIcon,
  Theme,
  Toolbar as MuiToolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import { HatchingChick } from '../assets/icons';
import { Share } from '@material-ui/icons';
import { Alert as MuiAlert, AlertTitle } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { copyHrefAction } from '../store/notification/notification-actions';
import { AppMenu } from './AppMenu';

const FlexSpacer: FC = () => (
  <div style={{ display: 'flex', flexGrow: 1 }} />
);

const SvgIcon = withStyles({
  root: {
    fontSize: '3em',
    marginRight: '0.25em',
  },
})(MuiSvgIcon);

const Toolbar = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 56,
      paddingLeft: 10,
      paddingRight: 6,
    },
    paddingRight: 6,
  },
}))(MuiToolbar);

const Alert = withStyles({
  message: {
    marginBottom: -8,
  },
  root: {
    alignItems: 'center',
  },
})(MuiAlert);

const IconButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
}))(MuiIconButton);

const Button = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.contrastText,
  },
}))(MuiButton);

interface ButtonMorphProps {
  description: string;
  icon: JSX.Element;
  onClick: () => void;
  title: string;
}
const ButtonMorph: FC<ButtonMorphProps> = ({ description, icon, onClick, title }) => {
  const breakpoint = useMediaQuery(useTheme().breakpoints.down('sm'));

  if (breakpoint) {
    return (
      <Tooltip title={description}>
        <IconButton
          aria-label="share"
          onClick={onClick}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={description}>
      <Button
        aria-label="share"
        onClick={onClick}
        size="large"
        startIcon={icon}
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export const TopBar: FC = () => {
  const dispatch = useDispatch();
  const [snackbarIsVisible, setSnackbarIsVisible] = useState(false);
  const closeSnackBar = () => {
    setSnackbarIsVisible(false);
  };
  return (
    <AppBar position="static">
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={2500}
        onClose={closeSnackBar}
        open={snackbarIsVisible}
      >
        <Alert onClose={closeSnackBar}>
          <AlertTitle>Copied Sharable Link!</AlertTitle>
        </Alert>
      </Snackbar>

      <Toolbar>
        <Container maxWidth="xl" style={{ alignItems: 'center', display: 'flex', padding: 0 }}>
          <SvgIcon><HatchingChick /></SvgIcon>
          <Typography variant="h5">Name Hatcher</Typography>

          <FlexSpacer />

          <ButtonMorph
            description="get a sharable link"
            icon={<Share />}
            onClick={() => {
              dispatch(copyHrefAction());
              setSnackbarIsVisible(true);
            }}
            title="Share"
          />
          <AppMenu />
        </Container>

      </Toolbar>
    </AppBar >
  );
};
