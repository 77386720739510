import { createAction, createAsyncAction } from 'typesafe-actions';
import { Filter, Sort, NameId } from 'common/entities';

export const setLimitAction = createAction('search/SET_LIMIT')<number>();

export const addFilterAction = createAction('search/ADD_FILTER')<Filter>();
export const removeFilterAction = createAction('search/REMOVE_FILTER')<number>();
export const modifyFilterAction = createAction('search/MODIFY_FILTER')<{
  index: number;
  filter: Filter;
}>();

export const addSortAction = createAction('search/ADD_SORT')<Sort>();
export const removeSortAction = createAction('search/REMOVE_SORT')<number>();
export const modifySortAction = createAction('search/MODIFY_SORT')<{
  index: number;
  sort: Sort;
}>();

export const fetchSearchAsyncAction = createAsyncAction(
  'database/FETCH_SEARCH_REQUEST',
  'database/FETCH_SEARCH_SUCCESS',
  'database/FETCH_SEARCH_FAILURE',
)<undefined, { matchCount: number; nameIds: NameId[]; yearRangeCount: number }, Error>();
